import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import {
	CREATE_BRANCH,
	UPDATE_BRANCH,
	DELETE_BRANCH,
	CREATE_BRANCH_BULK,
	SUBMIT_BRANCH_FORM,
	GET_APPROVAL_BRANCH_LIST,
	APPROVE_BRANCH,
	REJECT_BRANCH,
} from 'redux/constants/Branch';
import {
	branchCreated,
	createBranchError,
	branchUpdated,
	branchDeleted,
	updateBranchError,
	deleteBranchError,
	waitBranchCreation,
	bulkBranchCreated,
	waitBranchUpdate,
	submitBranchFormError,
	submitBranchFormSuccess,
	waitSubmitBranchForm,
	waitGetApprovalBranchList,
	getApprovalBranchListSuccess,
	getApprovalBranchListError,
	waitApproveBranch,
	approveBranchError,
	approveBranchSuccess,
	getApprovalBranchList,
	waitRejectBranch,
	rejectBranchError,
	rejectBranchSuccess,
} from '../actions/Branch';
import { signOut } from '../actions/Auth';
import BranchService from 'services/BranchService';
import { getUser } from 'redux/actions/User';
import ParserService from 'services/ParserService';
import _ from 'lodash';

export function* createBranch() {
	yield takeEvery(CREATE_BRANCH, function* ({ payload }) {
		try {
			const { uid, companyId, branch } = payload;
			yield put(waitBranchCreation());
			const newBranch = yield call(BranchService.createBranch, companyId, branch);
			yield put(branchCreated(companyId, newBranch));
			yield put(getUser(uid));
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				let message = '';
				if (error && error.response && error.response.data) {
					message = error.response.data.errorDetail;
				}
				yield put(createBranchError(message));
			}
		}
	});
}

export function* updateBranch() {
	yield takeEvery(UPDATE_BRANCH, function* ({ payload }) {
		try {
			const { companyId, branch } = payload;
			yield put(waitBranchUpdate());
			const updatedBranch = yield call(BranchService.updateBranch, companyId, branch);
			yield put(branchUpdated(companyId, updatedBranch));
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				yield put(updateBranchError(''));
			}
		}
	});
}

export function* deleteBranch() {
	yield takeEvery(DELETE_BRANCH, function* ({ payload }) {
		try {
			const { companyId, branchId } = payload;
			yield call(BranchService.deleteBranch, companyId, branchId);
			yield put(branchDeleted(companyId, branchId));
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				yield put(deleteBranchError(''));
			}
		}
	});
}

export function* createBranchBulk() {
	yield takeEvery(CREATE_BRANCH_BULK, function* ({ payload }) {
		try {
			const { uid, companyId, branchList } = payload;
			yield put(waitBranchCreation());
			const response = yield call(BranchService.createBranchBulk, companyId, branchList);
			yield put(bulkBranchCreated(response.successList, response.failList));
			yield put(getUser(uid));
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				yield put(createBranchError(''));
			}
		}
	});
}

export function* submitBranchForm() {
	yield takeEvery(SUBMIT_BRANCH_FORM, function* ({ payload }) {
		try {
			yield put(waitSubmitBranchForm());
			const response = yield call(ParserService.branchFormControl, payload);
			yield put(submitBranchFormSuccess(response));
		} catch (error) {
			let message = '';
			if (error && error.response && error.response.data) {
				message = error.response.data.errorDetail;
			}
			yield put(submitBranchFormError(message));
		}
	});
}

export function* getApprovalBranchListFnc() {
	yield takeEvery(GET_APPROVAL_BRANCH_LIST, function* () {
		try {
			yield put(waitGetApprovalBranchList());
			const response = yield call(BranchService.getApprovalBranchList);
			if (response) {
				let approvalList = _.find(response, { id: process.env.REACT_APP_COMPANY_ID });
				let branchList = approvalList && approvalList.branchList ? approvalList.branchList : [];
				yield put(getApprovalBranchListSuccess(branchList));
			} else {
				yield put(getApprovalBranchListSuccess([]));
			}
		} catch (error) {
			let message = error;
			if (error && error.response && error.response.data) {
				message = error.response.data.errorDetail;
			}
			yield put(getApprovalBranchListError(message));
		}
	});
}

export function* approveBranch() {
	yield takeEvery(APPROVE_BRANCH, function* ({ payload }) {
		try {
			const { companyId, branch } = payload;
			yield put(waitApproveBranch());
			const response = yield call(BranchService.approveBranch, companyId, branch);
			if (response && response === 'Success') {
				yield put(approveBranchSuccess('Acente başarıyla kaydedilmiştir.'));
				yield put(getApprovalBranchList());
			} else {
				yield put(approveBranchError(response ? response : 'Seçili acente kaydedilirken bir hata oluştu.'));
			}
		} catch (error) {
			let message = 'Seçili acente kaydedilirken bir hata oluştu.';
			if (error) {
				if (error.message) {
					message = error.message;
				} else {
					message = error;
				}
			}
			yield put(approveBranchError(message));
		}
	});
}

export function* rejectBranch() {
	yield takeEvery(REJECT_BRANCH, function* ({ payload }) {
		try {
			const { companyId, sgkId } = payload;
			yield put(waitRejectBranch());
			yield call(BranchService.rejectBranch, companyId, sgkId);
			yield put(rejectBranchSuccess('İlgili acente kaydı silinmiştir.'));
			yield put(getApprovalBranchList());
		} catch (error) {
			let message = 'Seçili acente kaydı silinirken bir hata oluştu.';
			if (error) {
				if (error.message) {
					message = error.message;
				} else {
					message = error;
				}
			}
			yield put(rejectBranchError(message));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(createBranch),
		fork(updateBranch),
		fork(deleteBranch),
		fork(createBranchBulk),
		fork(submitBranchForm),
		fork(getApprovalBranchListFnc),
		fork(approveBranch),
		fork(rejectBranch),
	]);
}
