import fetch from 'auth/FetchInterceptor';
import { BRANCH_SERVICE_URL } from 'constants/ApiConstant';

const branchService = {};

branchService.createBranch = async (companyId, branch) => {
	return await fetch({
		url: `${BRANCH_SERVICE_URL}/${companyId}`,
		method: 'post',
		data: branch,
	});
};

branchService.updateBranch = async (companyId, branch) => {
	return await fetch({
		url: `${BRANCH_SERVICE_URL}/${companyId}`,
		method: 'put',
		data: branch,
	});
};

branchService.deleteBranch = async (companyId, sgkId) => {
	return await fetch({
		url: `${BRANCH_SERVICE_URL}/${companyId}/${sgkId}`,
		method: 'delete',
	});
};

branchService.createBranchBulk = async (companyId, branchList) => {
	return await fetch({
		url: `${BRANCH_SERVICE_URL}/bulk/${companyId}`,
		method: 'post',
		data: branchList,
	});
};

branchService.getApprovalBranchList = async () => {
	return await fetch({
		url: `${BRANCH_SERVICE_URL}/temp`,
		method: 'get',
	});
};

branchService.approveBranch = async (companyId, branch) => {
	return await fetch({
		url: `${BRANCH_SERVICE_URL}/approve/${companyId}`,
		method: 'post',
		data: branch,
	});
};

branchService.rejectBranch = async (companyId, sgkId) => {
	return await fetch({
		url: `${BRANCH_SERVICE_URL}/${companyId}/temp/${sgkId}`,
		method: 'delete',
	});
};

export default branchService;
