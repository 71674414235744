import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { signOut } from '../actions/Auth';
import SGKService from 'services/SGKService';
import { JOB_QUIT_BULK, JOB_QUIT_MULTI, JOB_START_BULK, JOB_START_MULTI } from 'redux/constants/SGK';
import {
	jobQuitError,
	jobQuitSuccess,
	jobStartError,
	jobStartSuccess,
	waitJobQuit,
	waitJobStart,
} from 'redux/actions/SGK';
import _ from 'lodash';

export function* jobStartMulti() {
	yield takeEvery(JOB_START_MULTI, function* ({ payload }) {
		try {
			const { branchId, employeeList } = payload;
			yield put(waitJobStart());
			const response = yield call(SGKService.jobStartMulti, branchId, employeeList);
			if (response && response.hataKodu === 0) {
				let referenceCodeList = [];
				let errorMessage = '';
				_.each(response.sigortaliIseGirisSonuc, (result) => {
					if (result.islemSonucu === -1) {
						errorMessage = result.islemAciklamasi;
						return false;
					} else {
						referenceCodeList.push(result.referansKodu);
					}
				});
				if (!_.isEmpty(errorMessage)) {
					yield put(jobStartError(errorMessage));
					return;
				}
				yield put(jobStartSuccess(referenceCodeList));
			}
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				yield put(jobStartError(''));
			}
		}
	});
}

export function* jobStartBulk() {
	yield takeEvery(JOB_START_BULK, function* ({ payload }) {
		try {
			const { file } = payload;
			yield put(waitJobStart());
			const response = yield call(SGKService.jobStartBulk, file);
			if (_.isEmpty(response) || response[0].hataKodu !== 0) {
				yield put(jobStartError(_.isEmpty(response) ? 'Servis çağırımında hata!' : response[0].hataAciklamasi));
				return;
			}
			yield put(jobStartSuccess(response));
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				yield put(jobStartError(''));
			}
		}
	});
}

export function* jobQuitMulti() {
	yield takeEvery(JOB_QUIT_MULTI, function* ({ payload }) {
		try {
			const { branchId, employeeList } = payload;
			yield put(waitJobQuit());
			const response = yield call(SGKService.jobQuitMulti, branchId, employeeList);
			if (response && response.hataKodu === 0) {
				let referenceCodeList = [];
				let errorMessage = '';
				_.each(response.sigortaliIstenCikisSonuc, (result) => {
					if (result.islemSonucu === -1) {
						errorMessage = result.islemAciklamasi;
						return false;
					} else {
						referenceCodeList.push(result.referansKodu);
					}
				});
				if (!_.isEmpty(errorMessage)) {
					yield put(jobQuitError(errorMessage));
					return;
				}
				yield put(jobQuitSuccess(referenceCodeList));
			}
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				yield put(jobQuitError(''));
			}
		}
	});
}

export function* jobQuitBulk() {
	yield takeEvery(JOB_QUIT_BULK, function* ({ payload }) {
		try {
			const { file } = payload;
			yield put(waitJobQuit());
			const response = yield call(SGKService.jobQuitBulk, file);
			if (_.isEmpty(response) || response[0].hataKodu !== 0) {
				yield put(jobQuitError(_.isEmpty(response) ? 'Servis çağırımında hata!' : response[0].hataAciklamasi));
				return;
			}
			yield put(jobQuitSuccess(response));
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				yield put(jobQuitError(''));
			}
		}
	});
}

export default function* rootSaga() {
	yield all([fork(jobStartMulti), fork(jobStartBulk), fork(jobQuitMulti), fork(jobQuitBulk)]);
}
