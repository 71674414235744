import {
    GET_DEBT_LIST,
    GET_DEBT_LIST_SUCCESS,
    GET_DEBT_LIST_ERROR,
    REFRESH_DEBT_DATA,
    DEBTS_LOADING,
    GET_MNG_DASHBOARD_STATS,
    GET_MNG_DASHBOARD_STATS_SUCCESS,
    GET_MNG_DASHBOARD_STATS_ERROR,
    GET_EMPLOYEE_LIST,
    GET_EMPLOYEE_LIST_SUCCESS,
    GET_EMPLOYEE_LIST_ERROR,
    GET_ALL_EMPLOYEE_LIST,
    GET_ALL_EMPLOYEE_LIST_SUCCESS,
    GET_ALL_EMPLOYEE_LIST_ERROR,
    EMPLOYEE_LIST_LOADING,
    REFRESH_LIVE_DATA,
    REFRESH_LIVE_DATA_LOADING,
    REFRESH_LIVE_DATA_LOADED
} from '../constants/Data';

export const getDebtList = (companyId) => {
    return {
        type: GET_DEBT_LIST,
        payload: {
            companyId
        }
    };
};

export const getDebtListSuccess = (companyId, debtList) => {
    return {
        type: GET_DEBT_LIST_SUCCESS,
        payload: { companyId, debtList }
    };
};

export const getDebtListError = (message) => {
    return {
        type: GET_DEBT_LIST_ERROR,
        message
    };
};

export const refreshDebtData = (companyId, sgkId) => {
    return {
        type: REFRESH_DEBT_DATA,
        payload: {
            companyId,
            sgkId
        }
    };
};

export const debtsLoading = () => {
    return {
        type: DEBTS_LOADING
    };
};

export const getEmployeeList = (companyId, sgkId, period) => {
    return {
        type: GET_EMPLOYEE_LIST,
        payload: { companyId, sgkId, period }
    };
};

export const employeeListLoading = () => {
    return {
        type: EMPLOYEE_LIST_LOADING
    };
};

export const getEmployeeListSuccess = (companyId, employeeList) => {
    return {
        type: GET_EMPLOYEE_LIST_SUCCESS,
        payload: { companyId, employeeList }
    };
};

export const getEmployeeListError = (message) => {
    return {
        type: GET_EMPLOYEE_LIST_ERROR,
        message
    };
};

export const getAllEmployeeList = (companyId) => {
    return {
        type: GET_ALL_EMPLOYEE_LIST,
        payload: { companyId }
    };
};

export const getAllEmployeeListSuccess = (companyId, employeeList) => {
    return {
        type: GET_ALL_EMPLOYEE_LIST_SUCCESS,
        payload: { companyId, employeeList }
    };
};

export const getAllEmployeeListError = (message) => {
    return {
        type: GET_ALL_EMPLOYEE_LIST_ERROR,
        message
    };
};

export const getMngDashboardStats = (uid) => {
    return {
        type: GET_MNG_DASHBOARD_STATS,
        payload: {
            uid
        }
    };
};

export const getMngDashboardStatsSuccess = (mngDashboardStats) => {
    return {
        type: GET_MNG_DASHBOARD_STATS_SUCCESS,
        payload: mngDashboardStats
    };
};

export const getMngDashboardStatsError = (message) => {
    return {
        type: GET_MNG_DASHBOARD_STATS_ERROR,
        message
    };
};

export const refreshLiveData = (companyId) => {
    return {
        type: REFRESH_LIVE_DATA,
        payload: { companyId }
    };
};

export const refreshLiveDataLoading = () => {
    return {
        type: REFRESH_LIVE_DATA_LOADING
    };
};

export const refreshLiveDataLoaded = () => {
    return {
        type: REFRESH_LIVE_DATA_LOADED
    };
};
