import {
	HomeTwoTone,
	UserAddOutlined,
	OrderedListOutlined,
	ProjectFilled,
	RadiusUpleftOutlined,
	FileFilled,
} from '@ant-design/icons';

const dashBoardNavTree = [
	{
		key: 'home',
		adminOnly: false,
		path: `/dashboard`,
		title: 'Ana Sayfa',
		icon: HomeTwoTone,
		breadcrumb: false,
		submenu: [],
	},

	{
		key: 'sgk',
		adminOnly: false,
		path: '',
		title: 'SGK İşlemleri',
		breadcrumb: false,
		submenu: [
			{
				key: 'sgk-job-start',
				adminOnly: false,
				path: `/sgk-job-start`,
				icon: ProjectFilled,
				title: 'İşe Giriş İşlemleri',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-job-quit',
				adminOnly: false,
				path: `/sgk-job-quit`,
				icon: ProjectFilled,
				title: 'İşten Çıkış İşlemleri',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-job-start-quit-bulk',
				adminOnly: false,
				path: `/sgk-job-start-quit-bulk`,
				icon: ProjectFilled,
				title: 'İşe Giriş-İşten Çıkış Toplu Görüntüle',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'encouragement-potential-inquiry',
				adminOnly: false,
				path: `/encouragement-potential-inquiry`,
				icon: ProjectFilled,
				title: 'Potansiyel Teşvik Sorgulama',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'encouragement-unjust-control',
				adminOnly: false,
				path: `/encouragement-unjust-control`,
				icon: ProjectFilled,
				title: 'Yersiz Teşvik Kontrolü',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'reports',
		adminOnly: false,
		path: '',
		title: 'Raporlar Bildirimleri',
		breadcrumb: false,
		submenu: [
			{
				key: 'report-entrance',
				adminOnly: false,
				path: `/report-entrance`,
				title: 'Rapor Girişi',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'report-offset',
				adminOnly: false,
				path: `/report-offset`,
				title: 'Mahsuplaşma İşlemleri',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'occupational-accident-inquiry',
				adminOnly: false,
				path: `/occupational-accident-inquiry`,
				title: 'İş Kazası ve Meslek Hastalığı Sorgulama',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'approved-reports',
				adminOnly: false,
				path: `/approved-reports`,
				icon: RadiusUpleftOutlined,
				title: 'Onaylı Rapor Sorgulama',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'sgk-mufredat-card',
		adminOnly: false,
		path: '',
		icon: ProjectFilled,
		title: 'Müfredat Kartı İşlemleri',
		breadcrumb: false,
		submenu: [
			{
				key: 'sgk-mufredat-debts',
				adminOnly: false,
				path: `/sgk-mufredat-debts`,
				title: 'Borç Görüntüleme',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-mufredat-deposit',
				adminOnly: false,
				path: `/sgk-mufredat-deposit`,
				title: 'Emanet Hesap Görüntüleme',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-mufredat-min-wage-support',
				adminOnly: false,
				path: `/sgk-mufredat-min-wage-support`,
				title: 'Asgari Ücret Desteği Görüntüleme',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'home',
		adminOnly: false,
		path: `/service-list`,
		title: 'Hizmet Listesi ve Tahakkuk Görüntüleme',
		icon: OrderedListOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: 'firm',
		adminOnly: false,
		path: '',
		title: 'Firma Kullanıcı İşlemleri',
		breadcrumb: false,
		submenu: [
			{
				key: 'firm-add',
				adminOnly: false,
				path: `/firm-add`,
				icon: FileFilled,
				title: 'İşyeri Ekleme',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'firm-add-bulk',
				adminOnly: false,
				path: `/firm-add-bulk`,
				icon: FileFilled,
				title: 'İşyeri Toplu Ekleme',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'firm-list',
				adminOnly: false,
				path: `/firm-list`,
				icon: FileFilled,
				title: 'Liste Görüntüleme',
				breadcrumb: false,
				submenu: [],
			},
		],
	},
	{
		key: 'sgk-shortcuts',
		adminOnly: false,
		path: '',
		icon: ProjectFilled,
		title: 'SGK Kısayolları',
		breadcrumb: false,
		submenu: [],
	},
	/*{
		key: 'sgk-shortcuts',
		adminOnly: false,
		path: '',
		icon: ProjectFilled,
		title: 'SGK Kısayolları',
		breadcrumb: false,
		submenu: [
			{
				key: 'sgk-shortcut-e-decleration-v1',
				adminOnly: false,
				path: `/sgk-shortcut-e-decleration-v1`,
				title: 'E-Bildirge v1',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-shortcut-e-decleration-v2',
				adminOnly: false,
				path: `/sgk-shortcut-e-decleration-v2`,
				title: 'E-Bildirge v2',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-shortcut-employer',
				adminOnly: false,
				path: `/sgk-shortcut-employer`,
				title: 'İşveren Sistemi',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-shortcut-visits',
				adminOnly: false,
				path: `/sgk-shortcut-visits`,
				title: 'Vizite Ekranı',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-shortcut-e-no-debt',
				adminOnly: false,
				path: `/sgk-shortcut-e-no-debt`,
				title: 'E-Borcu Yoktur',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-shortcut-job-start-quit',
				adminOnly: false,
				path: `/sgk-shortcut-job-start-quit`,
				title: 'İşe Giriş-İşten Çıkış',
				breadcrumb: false,
				submenu: [],
			},
		],
	},*/
	/*{
		key: 'iskur',
		adminOnly: false,
		path: `/iskur`,
		icon: FileFilled,
		title: 'İŞKUR İşlemleri',
		breadcrumb: false,
		submenu: [],
	},*/
	/*{
		key: 'identity',
		adminOnly: false,
		path: `/identity`,
		icon: FileFilled,
		title: 'Kimlik Bildirimleri',
		breadcrumb: false,
		submenu: [],
	},*/
	/*{
		key: 'payroll',
		adminOnly: false,
		path: `/payroll`,
		icon: FileFilled,
		title: 'Bordro Entegrasyon',
		breadcrumb: false,
		submenu: [],
	},
	{
		key: 'createUser',
		adminOnly: true,
		path: `/new-user`,
		title: 'Yeni Kullanıcı',
		icon: UserAddOutlined,
		breadcrumb: true,
		submenu: [],
	},*/
	/*{
		key: 'encouragement',
		adminOnly: false,
		path: '',
		title: 'Teşvik İşlemleri',
		breadcrumb: false,
		submenu: [
			{
				key: 'encouragement-potential',
				adminOnly: false,
				path: '',
				icon: OrderedListOutlined,
				title: 'Potansiyel Teşvik Sorgulama',
				breadcrumb: false,
				submenu: [
					{
						key: 'encouragement-potential-inquiry',
						adminOnly: false,
						path: `/encouragement-potential-inquiry`,
						title: 'Potansiyel Sorgulama',
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'encouragement-potential-bulk-inquiry',
						adminOnly: false,
						path: `/encouragement-potential-bulk-inquiry`,
						title: 'Potansiyel Sorgulama (Toplu)',
						breadcrumb: false,
						submenu: [],
					},
				],
			},
			{
				key: 'current-encouragement',
				adminOnly: false,
				path: '',
				icon: OrderedListOutlined,
				title: 'Cari Teşvik Çalışması',
				breadcrumb: false,
				submenu: [
					{
						key: 'current-encouragement-excel',
						adminOnly: false,
						path: `/current-encouragement-excel`,
						title: "Excel'den Hesaplama",
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'current-encouragement-mphb',
						adminOnly: false,
						path: `/current-encouragement-mphb`,
						title: 'MPHB Hesaplama',
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'current-encouragement-system',
						adminOnly: false,
						path: `/current-encouragement-system`,
						title: 'Sistemden Hesaplama',
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'current-encouragement-xml',
						adminOnly: false,
						path: `/current-encouragement-xml`,
						title: 'XML Hesaplama',
						breadcrumb: false,
						submenu: [],
					},
				],
			},
			{
				key: 'past-terms-encouragement',
				adminOnly: false,
				path: `/past-terms-encouragement`,
				icon: OrderedListOutlined,
				title: 'Geçmiş Dönem Teşvik İşlemleri',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'encouragement-unjust-control',
				adminOnly: false,
				path: `/encouragement-unjust-control`,
				title: 'Teşvik Yersiz Kontrolü',
				icon: OrderedListOutlined,
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'encouragement-employees',
				adminOnly: false,
				path: '',
				icon: OrderedListOutlined,
				title: 'Teşvik Çalışan Listesi Görüntüleme',
				breadcrumb: false,
				submenu: [
					{
						key: 'encouragement-employees-save',
						adminOnly: false,
						path: `/encouragement-employees-save`,
						title: 'Cari Teşvik Listesi Kaydet',
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'encouragement-employees-list',
						adminOnly: false,
						path: `/encouragement-employees-list`,
						title: 'Teşvik Listesi Görüntüle',
						breadcrumb: false,
						submenu: [],
					},
					{
						key: 'encouragement-employees-active',
						adminOnly: false,
						path: `/encouragement-employees-active`,
						title: 'Çalışan (Aktif) Personel Teşvik Listesi',
						breadcrumb: false,
						submenu: [],
					},
				],
			},
		],
	},*/
	/*{
				key: 'sgk-approval-documents',
				adminOnly: false,
				path: `/sgk-approval-documents`,
				icon: ProjectFilled,
				title: 'Onayda Bekleyen Belge Görüntüleme',
				breadcrumb: false,
				submenu: [],
			},
			{
				key: 'sgk-change-document-type',
				adminOnly: false,
				path: `/sgk-change-document-type`,
				icon: ProjectFilled,
				title: 'Belge Türü Değiştirme',
				breadcrumb: false,
				submenu: [],
			},*/
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
