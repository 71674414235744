import {
	AUTH_TOKEN,
	AUTHENTICATED,
	GET_PHONE_NUMBER,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	GET_VERIFICATION_CODE,
	UID,
} from '../constants/Auth';

const initState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN),
	uid: localStorage.getItem(UID),
};

const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return {
				...state,
				loading: false,
				redirect: '/',
				uid: action.payload.uid,
				token: action.payload.token,
				userType: action.payload.userObject ? action.payload.userObject.userType : '',
				userGroup: action.payload.userObject ? action.payload.userObject.group : '',
				userRegion: action.payload.userObject ? action.payload.userObject.region : '',
			};
		case GET_PHONE_NUMBER:
			return {
				...state,
				loading: false,
				user: action.user,
				pageMode: action.type,
			};
		case GET_VERIFICATION_CODE:
			return {
				...state,
				loading: false,
				pageMode: action.type,
				verificationId: action.payload.verificationId,
				user: action.payload.user,
				resolver: action.payload.resolver,
			};
		case SHOW_AUTH_MESSAGE:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false,
			};
		case HIDE_AUTH_MESSAGE:
			return {
				...state,
				message: '',
				showMessage: false,
			};
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false,
				pageMode: 'LOGIN',
			};
		}
		case SIGNUP_SUCCESS: {
			return {
				...state,
				loading: false,
				token: action.token,
			};
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true,
			};
		}
		default:
			return state;
	}
};

export default auth;
