import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { LOAD_MENU } from '../constants/App';
import { menuLoaded } from '../actions/App';

import DataService from 'services/DataService';

export function* loadMenu() {
	yield takeEvery(LOAD_MENU, function* ({ group }) {
		const menuTree = yield call(DataService.getMenuByGroup, group);
		yield put(menuLoaded(menuTree ? menuTree.menu : []));
	});
}

export default function* rootSaga() {
	yield all([fork(loadMenu)]);
}
