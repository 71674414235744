import fetch from 'auth/FetchInterceptor';
import { SGK_SERVICE_URL } from 'constants/ApiConstant';

const sgkService = {};

sgkService.jobStartMulti = async (branchId, employeeList) => {
	return await fetch({
		url: `${SGK_SERVICE_URL}/employment/start`,
		method: 'post',
		data: {
			sgkId: branchId,
			sigortaliIseGirisListesi: employeeList,
		},
	});
};

sgkService.jobStartBulk = async (file) => {
	let data = new FormData();
	data.append('file', file);
	return await fetch({
		url: `${SGK_SERVICE_URL}/employment/start-bulk`,
		method: 'post',
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};

sgkService.printJobStartDocuments = async (sgkId, referenceCode) => {
	return await fetch({
		url: `${SGK_SERVICE_URL}/employment/start-print`,
		method: 'post',
		data: {
			referansKodu: referenceCode,
			sgkId,
		},
	});
};

sgkService.jobQuitMulti = async (branchId, employeeList) => {
	return await fetch({
		url: `${SGK_SERVICE_URL}/employment/end`,
		method: 'post',
		data: {
			sgkId: branchId,
			sigortaliIstenCikisListesi: employeeList,
		},
	});
};

sgkService.jobQuitBulk = async (file) => {
	let data = new FormData();
	data.append('file', file);
	return await fetch({
		url: `${SGK_SERVICE_URL}/employment/end-bulk`,
		method: 'post',
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
	});
};

sgkService.printJobQuitDocuments = async (sgkId, referenceCode) => {
	return await fetch({
		url: `${SGK_SERVICE_URL}/employment/end-print`,
		method: 'post',
		data: {
			referansKodu: referenceCode,
			sgkId,
		},
	});
};

export default sgkService;
