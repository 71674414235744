import {
	CREATE_BRANCH,
	BRANCH_CREATED,
	CREATE_BRANCH_ERROR,
	UPDATE_BRANCH,
	BRANCH_UPDATED,
	UPDATE_BRANCH_ERROR,
	DELETE_BRANCH,
	BRANCH_DELETED,
	DELETE_BRANCH_ERROR,
	WAIT_BRANCH_CREATION,
	WAIT_BRANCH_UPDATE,
	CREATE_BRANCH_BULK,
	BULK_BRANCH_CREATED,
	CLEAR_MESSAGE,
	ADD_BRANCH,
	UPDATE_BRANCH_IN_STORE,
	SUBMIT_BRANCH_FORM,
	SUBMIT_BRANCH_FORM_SUCCESS,
	SUBMIT_BRANCH_FORM_ERROR,
	WAIT_SUBMIT_BRANCH_FORM,
	GET_APPROVAL_BRANCH_LIST,
	WAIT_GET_APPROVAL_BRANCH_LIST,
	GET_APPROVAL_BRANCH_LIST_SUCCESS,
	GET_APPROVAL_BRANCH_LIST_ERROR,
	APPROVE_BRANCH,
	WAIT_APPROVE_BRANCH,
	APPROVE_BRANCH_SUCCESS,
	APPROVE_BRANCH_ERROR,
	REJECT_BRANCH,
	WAIT_REJECT_BRANCH,
	REJECT_BRANCH_SUCCESS,
	REJECT_BRANCH_ERROR,
} from '../constants/Branch';

export const clearMessage = () => {
	return {
		type: CLEAR_MESSAGE,
	};
};

export const createBranch = (uid, companyId, branch) => {
	return {
		type: CREATE_BRANCH,
		payload: {
			uid,
			companyId,
			branch,
		},
	};
};

export const addBranch = (companyId, branch) => {
	return {
		type: ADD_BRANCH,
		payload: {
			companyId,
			branch,
		},
	};
};

export const updateBranchInStore = (companyId, branch) => {
	return {
		type: UPDATE_BRANCH_IN_STORE,
		payload: {
			companyId,
			branch,
		},
	};
};

export const createBranchError = (message) => {
	return {
		type: CREATE_BRANCH_ERROR,
		message,
	};
};

export const branchCreated = (companyId, branch) => {
	return {
		type: BRANCH_CREATED,
		payload: { companyId, branch },
	};
};

export const waitBranchCreation = () => {
	return {
		type: WAIT_BRANCH_CREATION,
	};
};

export const updateBranch = (companyId, branch) => {
	return {
		type: UPDATE_BRANCH,
		payload: {
			companyId,
			branch,
		},
	};
};

export const waitBranchUpdate = () => {
	return {
		type: WAIT_BRANCH_UPDATE,
	};
};

export const updateBranchError = (message) => {
	return {
		type: UPDATE_BRANCH_ERROR,
		message,
	};
};

export const branchUpdated = (companyId, branch) => {
	return {
		type: BRANCH_UPDATED,
		payload: { companyId, branch },
	};
};

export const deleteBranch = (companyId, branchId) => {
	return {
		type: DELETE_BRANCH,
		payload: {
			companyId,
			branchId,
		},
	};
};

export const deleteBranchError = (message) => {
	return {
		type: DELETE_BRANCH_ERROR,
		message,
	};
};

export const branchDeleted = (companyId, sgkId) => {
	return {
		type: BRANCH_DELETED,
		payload: { companyId, sgkId },
	};
};

export const createBranchBulk = (uid, companyId, branchList) => {
	return {
		type: CREATE_BRANCH_BULK,
		payload: {
			uid,
			companyId,
			branchList,
		},
	};
};

export const bulkBranchCreated = (successList, failList) => {
	return {
		type: BULK_BRANCH_CREATED,
		payload: { successList, failList },
	};
};

export const submitBranchForm = (branch) => {
	return {
		type: SUBMIT_BRANCH_FORM,
		payload: branch,
	};
};

export const submitBranchFormSuccess = (message) => {
	return {
		type: SUBMIT_BRANCH_FORM_SUCCESS,
		payload: message,
	};
};

export const submitBranchFormError = (message) => {
	return {
		type: SUBMIT_BRANCH_FORM_ERROR,
		payload: message,
	};
};

export const waitSubmitBranchForm = () => {
	return {
		type: WAIT_SUBMIT_BRANCH_FORM,
	};
};

export const getApprovalBranchList = () => {
	return {
		type: GET_APPROVAL_BRANCH_LIST,
	};
};

export const waitGetApprovalBranchList = () => {
	return {
		type: WAIT_GET_APPROVAL_BRANCH_LIST,
	};
};

export const getApprovalBranchListSuccess = (branchList) => {
	return {
		type: GET_APPROVAL_BRANCH_LIST_SUCCESS,
		payload: branchList,
	};
};

export const getApprovalBranchListError = (message) => {
	return {
		type: GET_APPROVAL_BRANCH_LIST_ERROR,
		payload: message,
	};
};

export const approveBranch = (companyId, branch) => {
	return {
		type: APPROVE_BRANCH,
		payload: {
			companyId,
			branch,
		},
	};
};

export const waitApproveBranch = () => {
	return {
		type: WAIT_APPROVE_BRANCH,
	};
};

export const approveBranchSuccess = (message) => {
	return {
		type: APPROVE_BRANCH_SUCCESS,
		payload: message,
	};
};

export const approveBranchError = (message) => {
	return {
		type: APPROVE_BRANCH_ERROR,
		payload: message,
	};
};

export const rejectBranch = (companyId, sgkId) => {
	return {
		type: REJECT_BRANCH,
		payload: {
			companyId,
			sgkId,
		},
	};
};

export const waitRejectBranch = () => {
	return {
		type: WAIT_REJECT_BRANCH,
	};
};

export const rejectBranchSuccess = (message) => {
	return {
		type: REJECT_BRANCH_SUCCESS,
		payload: message,
	};
};

export const rejectBranchError = (message) => {
	return {
		type: REJECT_BRANCH_ERROR,
		payload: message,
	};
};
