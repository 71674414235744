export const GET_DEBT_LIST = 'GET_DEBT_LIST';
export const GET_DEBT_LIST_SUCCESS = 'GET_DEBT_LIST_SUCCESS';
export const GET_DEBT_LIST_ERROR = 'GET_DEBT_LIST_ERROR';
export const REFRESH_DEBT_DATA = 'REFRESH_DEBT_DATA';
export const DEBTS_LOADING = 'DEBTS_LOADING';
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const EMPLOYEE_LIST_LOADING = 'EMPLOYEE_LIST_LOADING';
export const GET_EMPLOYEE_LIST_SUCCESS = 'GET_EMPLOYEE_LIST_SUCCESS';
export const GET_EMPLOYEE_LIST_ERROR = 'GET_EMPLOYEE_LIST_ERROR';
export const GET_ALL_EMPLOYEE_LIST = 'GET_ALL_EMPLOYEE_LIST';
export const GET_ALL_EMPLOYEE_LIST_SUCCESS = 'GET_ALL_EMPLOYEE_LIST_SUCCESS';
export const GET_ALL_EMPLOYEE_LIST_ERROR = 'GET_ALL_EMPLOYEE_LIST_ERROR';
export const GET_MNG_DASHBOARD_STATS = 'GET_MNG_DASHBOARD_STATS';
export const GET_MNG_DASHBOARD_STATS_SUCCESS = 'GET_MNG_DASHBOARD_STATS_SUCCESS';
export const GET_MNG_DASHBOARD_STATS_ERROR = 'GET_MNG_DASHBOARD_STATS_ERROR';
export const REFRESH_LIVE_DATA = 'REFRESH_LIVE_DATA';
export const REFRESH_LIVE_DATA_LOADING = 'REFRESH_LIVE_DATA_LOADING';
export const REFRESH_LIVE_DATA_LOADED = 'REFRESH_LIVE_DATA_LOADED';
