import React, { useState } from 'react';
import { Button, Form, Input, Alert, Row, Col, Card } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import Utils from 'utils';

export const NewBranchFormLogin = ({ setAuthenticated }) => {
	const [message, setMessage] = useState('');

	const onSubmitLoginForm = async (values) => {
		let authenticated = await Utils.authenticateNewForm(values.password);
		if (!authenticated) {
			setMessage('Yanlış şifre girdiniz. Lütfen tekrar deneyiniz.');
		} else {
			setAuthenticated(true);
		}
	};

	const renderLoginForm = () => {
		return (
			<Form layout='vertical' name='login-form' onFinish={onSubmitLoginForm}>
				<Form.Item
					name='password'
					label={
						<div>
							<span>Şifre</span>
						</div>
					}
					rules={[
						{
							required: true,
							message: 'Lütfen şifre giriniz',
						},
					]}
				>
					<Input.Password prefix={<LockOutlined className='text-primary' />} />
				</Form.Item>
				<Form.Item>
					<Button type='primary' htmlType='submit' block id='loginButton'>
						Giriş yap
					</Button>
				</Form.Item>
			</Form>
		);
	};

	const backgroundStyle = {
		backgroundImage: 'url(/img/others/img-17.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
	};
	return (
		<div className='auth-container'>
			<div className='h-100' style={backgroundStyle}>
				<div className='container d-flex flex-column justify-content-center h-100'>
					<Row justify='center'>
						<Col xs={20} sm={20} md={20} lg={7}>
							<Card>
								<div className='my-4'>
									<div className='text-center'>
										{<img className='img-fluid' src={`/img/logo.png`} alt='' />}
										<div
											style={{
												fontSize: '24px',
												color: 'steelblue',
												fontWeight: 'bold',
											}}
										></div>
									</div>
									<Row justify='center'>
										<Col xs={24} sm={24} md={20} lg={20}>
											<motion.div
												initial={{ opacity: 0, marginBottom: 0 }}
												animate={{
													opacity: message ? 1 : 0,
													marginBottom: message ? 20 : 0,
												}}
											>
												<Alert type='error' showIcon message={message}></Alert>
											</motion.div>
											{renderLoginForm()}
										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};
