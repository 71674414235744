import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { firebase } from 'auth/FirebaseAuth';
import {
	AUTH_TOKEN,
	UID,
	VERIFY_PHONE_NUMBER,
	COMPLETE_VERIFICATION,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
} from '../constants/Auth';
import {
	signOut as signOutAction,
	showAuthMessage,
	showLoading,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	getPhoneNumber,
	getVerificationCode,
	verifyPhoneNumber as verifyPhoneNumberAction,
} from '../actions/Auth';

import { loadMenu, showMenuLoading } from '../actions/App';

import FirebaseService from 'services/FirebaseService';
import UserService from 'services/UserService';
import { getApprovalBranchList } from 'redux/actions/Branch';

export function* signInWithFBEmail() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		var resolver;
		const { email, password, recaptchaVerifier } = payload;
		try {
			const user = yield call(FirebaseService.signInEmailRequest, email, password);
			if (user.code === 'auth/multi-factor-auth-required') {
				resolver = user.resolver;

				if (
					(resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID,
					recaptchaVerifier)
				) {
					const verificationId = yield call(
						FirebaseService.sendVerificationCodeForExistingPhone,
						resolver.hints[0],
						resolver.session,
						recaptchaVerifier
					);
					yield put(getVerificationCode(verificationId, resolver));
				}
			} else if (user.message) {
				if (user.code === 'auth/wrong-password') {
					yield put(showAuthMessage('Yanlış şifre girdiniz. Lütfen tekrar deneyiniz.'));
				} else {
					yield put(showAuthMessage(user.message));
				}
			} else {
				if (!user.user.emailVerified) {
					yield call(FirebaseService.sendVerificationEmail, user.user);
					yield put(
						showAuthMessage(
							'Devam etmek için e-postanıza gönderilen bağlantıya tıklayarak doğrulama yapınız.'
						)
					);
				} else if (!user.user.phoneNumber) {
					yield put(getPhoneNumber(user.user));
				} else {
					yield put(verifyPhoneNumberAction(user.user, user.user.phoneNumber, recaptchaVerifier));
				}
			}
		} catch (error) {
			yield put(showAuthMessage(error.message ? error.message : error));
		}
	});
}

export function* verifyPhoneNumber() {
	yield takeEvery(VERIFY_PHONE_NUMBER, function* ({ payload }) {
		const { user, phoneNumber, recaptchaVerifier } = payload;
		try {
			const verificationId = yield call(FirebaseService.verifyPhoneNumber, user, phoneNumber, recaptchaVerifier);
			yield put(getVerificationCode(verificationId, null, user));
		} catch (error) {
			yield put(showAuthMessage(error.message ? error.message : error));
		}
	});
}

export function* completeVerification() {
	yield takeEvery(COMPLETE_VERIFICATION, function* ({ payload }) {
		let { user, resolver, verificationId, verificationCode } = payload;
		try {
			const currentUser = yield call(
				FirebaseService.completePhoneVerification,
				user,
				resolver,
				verificationId,
				verificationCode
			);

			if (!user) user = currentUser.user;
			const token = yield call(FirebaseService.getAuthToken, currentUser);

			localStorage.setItem(UID, user.uid);
			localStorage.setItem(AUTH_TOKEN, token);
			yield put(showLoading());
			const userData = yield call(UserService.getUser, user.uid);
			if (userData.group) {
				yield put(showMenuLoading());
				yield put(loadMenu(userData.group));
				if ('mng' === userData.group) {
					yield put(getApprovalBranchList());
				}
			}
			yield put(authenticated(user.uid, token, userData));
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOutAction());
			} else if (error.code && error.code === 'auth/invalid-verification-code') {
				yield put(showAuthMessage('Girdiğiniz doğrulama kodu yanlıştır. Lütfen tekrar deneyiniz.'));
			} else {
				yield put(showAuthMessage(error.message ? error.message : error));
			}
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (error) {
			yield put(showAuthMessage(error.message ? error.message : error));
		}
	});
}

export function* signUpWithFBEmail() {
	yield takeEvery(SIGNUP, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(UID, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error.message ? error.message : error));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signInWithFBEmail),
		fork(verifyPhoneNumber),
		fork(completeVerification),
		fork(signOut),
		fork(signUpWithFBEmail),
	]);
}
