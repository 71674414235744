import _ from 'lodash';

import { AUTHENTICATED } from 'redux/constants/Auth';
import {
	BRANCH_CREATED,
	CREATE_BRANCH_ERROR,
	BRANCH_UPDATED,
	UPDATE_BRANCH_ERROR,
	BRANCH_DELETED,
	DELETE_BRANCH_ERROR,
	WAIT_BRANCH_CREATION,
	BULK_BRANCH_CREATED,
	CLEAR_MESSAGE,
	ADD_BRANCH,
	UPDATE_BRANCH_IN_STORE,
	WAIT_BRANCH_UPDATE,
	WAIT_SUBMIT_BRANCH_FORM,
	SUBMIT_BRANCH_FORM_ERROR,
	SUBMIT_BRANCH_FORM_SUCCESS,
	WAIT_GET_APPROVAL_BRANCH_LIST,
	GET_APPROVAL_BRANCH_LIST_ERROR,
	GET_APPROVAL_BRANCH_LIST_SUCCESS,
	WAIT_APPROVE_BRANCH,
	APPROVE_BRANCH_ERROR,
	APPROVE_BRANCH_SUCCESS,
	REJECT_BRANCH_SUCCESS,
	REJECT_BRANCH_ERROR,
	WAIT_REJECT_BRANCH,
} from 'redux/constants/Branch';

import { USER_UPDATED } from 'redux/constants/User';

const initState = {
	companyList: [],
	bulkErrorList: [],
	bulkSuccessList: [],
	approvalBranchList: [],
	message: '',
	refreshPage: false,
	showLoading: false,
	success: false,
};

const branch = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED: {
			return {
				...state,
				companyList: action.payload.userObject ? action.payload.userObject.companies : [],
				refreshPage: true,
				message: '',
			};
		}
		case CLEAR_MESSAGE: {
			return {
				...state,
				message: '',
				refreshPage: false,
				showLoading: false,
				success: false,
				bulkSuccessList: [],
				bulkErrorList: [],
			};
		}
		case BRANCH_CREATED: {
			const { companyId, branch } = action.payload;
			let updatedCompanyList = state.companyList;
			const itemIndex = updatedCompanyList.findIndex((company) => company.id === companyId);
			updatedCompanyList[itemIndex].branchList.push(branch);
			return {
				...state,
				companyList: updatedCompanyList,
				message: 'İşyeri başarıyla eklenmiştir.',
				refreshPage: true,
				showLoading: false,
				success: true,
			};
		}
		case BULK_BRANCH_CREATED: {
			let bulkSuccessList = _.concat(state.bulkSuccessList, action.payload.successList);
			let bulkErrorList = action.payload.failList;
			return {
				...state,
				branchList: _.concat(state.branchList, bulkSuccessList),
				bulkSuccessList,
				bulkErrorList,
				message: '',
				refreshPage: true,
				showLoading: false,
				success: true,
			};
		}
		case WAIT_BRANCH_UPDATE:
		case WAIT_BRANCH_CREATION:
		case WAIT_SUBMIT_BRANCH_FORM:
		case WAIT_GET_APPROVAL_BRANCH_LIST:
		case WAIT_APPROVE_BRANCH:
		case WAIT_REJECT_BRANCH:
			return {
				...state,
				showLoading: true,
				success: false,
			};
		case CREATE_BRANCH_ERROR:
		case UPDATE_BRANCH_ERROR:
		case DELETE_BRANCH_ERROR:
		case SUBMIT_BRANCH_FORM_ERROR:
		case GET_APPROVAL_BRANCH_LIST_ERROR:
		case APPROVE_BRANCH_ERROR:
		case REJECT_BRANCH_ERROR:
			return {
				...state,
				message: action.message,
				showLoading: false,
				success: false,
			};
		case BRANCH_UPDATED: {
			const { companyId, branch } = action.payload;
			let updatedCompanyList = state.companyList;
			let companyIndex = updatedCompanyList.findIndex((company) => company.id === companyId);
			let branchIndex = updatedCompanyList[companyIndex].branchList.findIndex(
				(currBranch) => branch.sgkId === currBranch.sgkId
			);
			updatedCompanyList[companyIndex].branchList[branchIndex] = branch;

			return {
				...state,
				companyList: updatedCompanyList,
				message: 'İşyeri başarıyla güncellenmiştir.',
				refreshPage: true,
				showLoading: false,
				success: true,
			};
		}
		case BRANCH_DELETED: {
			const { companyId, sgkId } = action.payload;
			let updatedCompanyList = state.companyList;
			let companyIndex = updatedCompanyList.findIndex((company) => company.id === companyId);
			updatedCompanyList[companyIndex].branchList = updatedCompanyList[companyIndex].branchList.filter(
				(branch) => branch.sgkId !== sgkId
			);

			return {
				...state,
				companyList: updatedCompanyList,
				message: 'İşyeri başarıyla silinmiştir.',
				refreshPage: true,
			};
		}
		case USER_UPDATED: {
			return {
				...state,
				companyList: action.payload.userObject ? action.payload.userObject.companies : [],
				refreshPage: true,
			};
		}
		case ADD_BRANCH:
			const { companyId, branch } = action.payload;
			let updatedCompanyList = state.companyList;
			const itemIndex = updatedCompanyList.findIndex((company) => company.id === companyId);
			updatedCompanyList[itemIndex].branchList.push(branch);
			return {
				...state,
				companyList: updatedCompanyList,
				message: '',
			};
		case UPDATE_BRANCH_IN_STORE: {
			let { companyId, branch } = action.payload;
			let updatedCompanyList = state.companyList;
			let companyIndex = updatedCompanyList.findIndex((company) => company.id === companyId);
			let branchIndex = updatedCompanyList.branchList.findIndex(
				(currBranch) => branch.sgkId === currBranch.sgkId
			);
			updatedCompanyList[companyIndex].branchList[branchIndex] = branch;
			return {
				...state,
				companyList: updatedCompanyList,
				message: '',
			};
		}
		case SUBMIT_BRANCH_FORM_SUCCESS: {
			return {
				...state,
				showLoading: false,
				success: true,
				message: action.payload,
			};
		}
		case GET_APPROVAL_BRANCH_LIST_SUCCESS:
			return {
				...state,
				showLoading: false,
				success: true,
				approvalBranchList: action.payload,
			};
		case APPROVE_BRANCH_SUCCESS:
		case REJECT_BRANCH_SUCCESS:
			return {
				...state,
				showLoading: false,
				success: true,
				message: action.payload,
			};
		default:
			return state;
	}
};

export default branch;
