import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseConfig from 'configs/FirebaseConfig';

firebase.initializeApp(firebaseConfig);

// firebase utils
const db = firebase.firestore();
firebase.auth().languageCode = 'tr';
const auth = firebase.auth();
const currentUser = auth.currentUser;

export { db, auth, currentUser, firebase };
