export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const CREATE_BRANCH = 'CREATE_BRANCH';
export const CREATE_BRANCH_ERROR = 'CREATE_BRANCH_ERROR';
export const BRANCH_CREATED = 'BRANCH_CREATED';
export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const UPDATE_BRANCH_ERROR = 'UPDATE_BRANCH_ERROR';
export const BRANCH_UPDATED = 'BRANCH_UPDATED';
export const DELETE_BRANCH = 'DELETE_BRANCH';
export const DELETE_BRANCH_ERROR = 'DELETE_BRANCH_ERROR';
export const BRANCH_DELETED = 'BRANCH_DELETED';
export const WAIT_BRANCH_CREATION = 'WAIT_BRANCH_CREATION';
export const WAIT_BRANCH_UPDATE = 'WAIT_BRANCH_UPDATE';
export const CREATE_BRANCH_BULK = 'CREATE_BRANCH_BULK';
export const BULK_BRANCH_CREATED = 'BULK_BRANCH_CREATED';
export const ADD_BRANCH = 'ADD_BRANCH';
export const UPDATE_BRANCH_IN_STORE = 'UPDATE_BRANCH_IN_STORE';
export const SUBMIT_BRANCH_FORM = 'SUBMIT_BRANCH_FORM';
export const SUBMIT_BRANCH_FORM_SUCCESS = 'SUBMIT_BRANCH_FORM_SUCCESS';
export const SUBMIT_BRANCH_FORM_ERROR = 'SUBMIT_BRANCH_FORM_ERROR';
export const WAIT_SUBMIT_BRANCH_FORM = 'WAIT_SUBMIT_BRANCH_FORM';
export const GET_APPROVAL_BRANCH_LIST = 'GET_APPROVAL_BRANCH_LIST';
export const WAIT_GET_APPROVAL_BRANCH_LIST = 'WAIT_GET_APPROVAL_BRANCH_LIST';
export const GET_APPROVAL_BRANCH_LIST_SUCCESS = 'GET_APPROVAL_BRANCH_LIST_SUCCESS';
export const GET_APPROVAL_BRANCH_LIST_ERROR = 'GET_APPROVAL_BRANCH_LIST_ERROR';
export const APPROVE_BRANCH = 'APPROVE_BRANCH';
export const WAIT_APPROVE_BRANCH = 'WAIT_APPROVE_BRANCH';
export const APPROVE_BRANCH_SUCCESS = 'APPROVE_BRANCH_SUCCESS';
export const APPROVE_BRANCH_ERROR = 'APPROVE_BRANCH_ERROR';
export const REJECT_BRANCH = 'REJECT_BRANCH';
export const WAIT_REJECT_BRANCH = 'WAIT_REJECT_BRANCH';
export const REJECT_BRANCH_SUCCESS = 'REJECT_BRANCH_SUCCESS';
export const REJECT_BRANCH_ERROR = 'REJECT_BRANCH_ERROR';
