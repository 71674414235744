import { MENU_LOADED, SHOW_MENU_LOADING } from '../constants/App';

const initState = {
	menuTree: [],
	loading: false,
};

const app = (state = initState, action) => {
	switch (action.type) {
		case MENU_LOADED:
			return {
				...state,
				menuTree: action.menuTree,
				loading: false,
			};
		case SHOW_MENU_LOADING:
			return {
				...state,
				loading: true,
			};
		default:
			return state;
	}
};

export default app;
