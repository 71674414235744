import { LOAD_MENU, MENU_LOADED, SHOW_MENU_LOADING } from '../constants/App';

export const loadMenu = (group) => {
	return {
		type: LOAD_MENU,
		group,
	};
};

export const showMenuLoading = (group) => {
	return {
		type: SHOW_MENU_LOADING,
	};
};

export const menuLoaded = (menuTree) => {
	return {
		type: MENU_LOADED,
		menuTree,
	};
};
