export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const JOB_START_MULTI = 'JOB_START_MULTI';
export const JOB_START_BULK = 'JOB_START_BULK';
export const JOB_START_ERROR = 'JOB_START_ERROR';
export const JOB_START_SUCCESS = 'JOB_START_SUCCESS';
export const WAIT_JOB_START = 'WAIT_JOB_START';
export const JOB_QUIT_MULTI = 'JOB_QUIT_MULTI';
export const JOB_QUIT_BULK = 'JOB_QUIT_BULK';
export const JOB_QUIT_ERROR = 'JOB_QUIT_ERROR';
export const JOB_QUIT_SUCCESS = 'JOB_QUIT_SUCCESS';
export const WAIT_JOB_QUIT = 'WAIT_JOB_QUIT';
