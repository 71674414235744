import _ from 'lodash';
import { Form, Button, Input, Select, Radio, Row, Col, Image, notification } from 'antd';
import { LockOutlined, PhoneOutlined } from '@ant-design/icons';
import MaskedInput from 'antd-mask-input';

import Utils from 'utils';
import logo from '../../assets/logo/new-branch-form-logo.jpg';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clearMessage, submitBranchForm } from 'redux/actions/Branch';
import { useEffect, useState } from 'react';
import { NewBranchFormLogin } from './new-branch-from-login';

const formLayout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

const borderStyle = {
	border: '1px solid #dadce0',
	borderRadius: '8px',
	marginTop: '12px',
};

const formStyle = {
	marginTop: '12px',
	padding: '24px',
	paddingTop: '22px',
	border: '1px solid #dadce0',
	borderRadius: '8px',
	backgroundColor: 'white',
};

const { Option } = Select;

const NewBranchForm = () => {
	const dispatch = useDispatch();
	const { showLoading, serviceMessage, success } = useSelector(
		({ branch }) => ({
			showLoading: branch.showLoading,
			serviceMessage: branch.message,
			success: branch.success,
		}),
		shallowEqual
	);

	const [authenticated, setAuthenticated] = useState(false);

	useEffect(() => {
		if (success && serviceMessage) {
			notification.success({ message: serviceMessage, duration: 0 });
			dispatch(clearMessage());
		}
	}, [serviceMessage, success]);

	const getRegions = () => {
		const regionOptions = [
			<Option key='' value=''>
				Hepsi
			</Option>,
		];
		_.each(Utils.getRegions(), (region) => {
			regionOptions.push(
				<Option key={region.key} value={region.key}>
					{region.value}
				</Option>
			);
		});

		return regionOptions;
	};

	const validateAndSaveBranchForm = (values) => {
		values.companyId = process.env.REACT_APP_COMPANY_ID;
		dispatch(submitBranchForm(values));
	};

	if (!authenticated) {
		return <NewBranchFormLogin setAuthenticated={setAuthenticated} />;
	}

	return (
		<div style={{ backgroundColor: 'rgb(234, 245, 248)' }}>
			<div className='container d-flex flex-column justify-content-center'>
				<Row justify='center'>
					<Col xs={4} sm={4} md={4} lg={4}></Col>
					<Col xs={18} sm={18} md={18} lg={18}>
						<Image preview={false} style={borderStyle} width='78%' src={logo} />
					</Col>
				</Row>
				<Row justify='center'>
					<Col xs={20} sm={20} md={16} lg={14}>
						<div style={formStyle}>
							<h3>İletişim Bilgileri</h3>
							<span>Lütfen SGK sistemine erişim için gerekli olan bilgileri giriniz.</span>
						</div>
					</Col>
				</Row>
				<Row justify='center'>
					<Col xs={20} sm={20} md={16} lg={14}>
						<Form
							{...formLayout}
							layout='horizontal'
							name='update-branch'
							labelAlign='left'
							style={formStyle}
							onFinish={validateAndSaveBranchForm}
						>
							<Form.Item
								name='phoneNumber'
								label='Telefon Numarası'
								rules={[
									{
										required: true,
										message: 'Telefon Numarası giriniz',
									},
								]}
							>
								<MaskedInput
									mask='111 111 11 11'
									name='phone'
									size='10'
									placeholder='532 123 45 67'
									prefix={<PhoneOutlined className='text-primary' />}
								/>
							</Form.Item>
							<Form.Item
								name='branchName'
								label='Acente Adı'
								rules={[
									{
										required: true,
										message: 'Acente Adı giriniz',
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item name='sgkId' label='Şube / Acente Sicil Numarası'>
								<Input />
							</Form.Item>
							<Form.Item name='region' label='Bölge'>
								<Select allowClear>{getRegions()}</Select>
							</Form.Item>
							<Form.Item
								name='sgkTitle'
								label='Acentenizin Ticari Unvanı'
								rules={[
									{
										required: true,
										message: 'Ticari Unvan giriniz',
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name='branchType'
								label='Acente Türü'
								rules={[
									{
										required: true,
										message: 'Acente Türü seçiniz',
									},
								]}
							>
								<Radio.Group>
									<Radio value='I'>Bireysel</Radio>
									<Radio value='C'>Kurumsal</Radio>
								</Radio.Group>
							</Form.Item>
							<Form.Item
								name='sgkUsername'
								label='Kullanıcı Adı'
								rules={[
									{
										required: true,
										message: 'Kullanıcı Adı giriniz',
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name='sgkBusinessCode'
								label='Kullanıcı Kodu'
								rules={[
									{
										required: true,
										message: 'Kullanıcı Kodu giriniz',
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name='sgkPassword'
								label='Sistem Şifresi'
								rules={[
									{
										required: true,
										message: 'Sistem Şifresi Giriniz',
									},
								]}
							>
								<Input.Password prefix={<LockOutlined className='text-primary' />} />
							</Form.Item>
							<Form.Item
								name='sgkCorporatePassword'
								label='İşyeri Şifresi'
								rules={[
									{
										required: true,
										message: 'İşyeri Şifresi Giriniz',
									},
								]}
							>
								<Input.Password prefix={<LockOutlined className='text-primary' />} />
							</Form.Item>
							<Form.Item>
								<Button type='primary' htmlType='submit' loading={showLoading}>
									Doğrula ve Kaydet
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default NewBranchForm;
