import {
    GET_DEBT_LIST_SUCCESS,
    GET_DEBT_LIST_ERROR,
    GET_MNG_DASHBOARD_STATS_SUCCESS,
    GET_MNG_DASHBOARD_STATS_ERROR,
    GET_EMPLOYEE_LIST_SUCCESS,
    GET_EMPLOYEE_LIST_ERROR,
    GET_ALL_EMPLOYEE_LIST_SUCCESS,
    GET_ALL_EMPLOYEE_LIST_ERROR,
    DEBTS_LOADING,
    EMPLOYEE_LIST_LOADING,
    REFRESH_LIVE_DATA_LOADING,
    REFRESH_LIVE_DATA_LOADED
} from 'redux/constants/Data';

const initState = {
    companyDebtList: {},
    companyEmployeeList: {},
    allCompanyEmployeeList: {},
    debtsLoading: false,
    employeesLoading: false,
    mngDashboardStats: {
        branchChartData: {},
        annualStatisticData: [],
        activeBranchData: []
    },
    message: '',
    liveDataLoading: false
};

const data = (state = initState, action) => {
    switch (action.type) {
        case GET_DEBT_LIST_SUCCESS: {
            const { companyId, debtList } = action.payload;
            let newCompanyDebtList = state.companyDebtList || {};
            newCompanyDebtList[companyId] = debtList;
            return {
                ...state,
                companyDebtList: newCompanyDebtList,
                debtsLoading: false,
                message: ''
            };
        }
        case GET_DEBT_LIST_ERROR: {
            return {
                ...state,
                debtsLoading: false,
                message: action.message
            };
        }
        case DEBTS_LOADING: {
            return {
                debtsLoading: true
            };
        }
        case EMPLOYEE_LIST_LOADING: {
            return {
                employeesLoading: true
            };
        }
        case GET_EMPLOYEE_LIST_SUCCESS: {
            const { companyId, employeeList } = action.payload;
            let newCompanyEmployeeList = state.companyEmployeeList || {};
            newCompanyEmployeeList[companyId] = employeeList;
            return {
                ...state,
                companyEmployeeList: newCompanyEmployeeList,
                message: '',
                employeesLoading: false
            };
        }
        case GET_EMPLOYEE_LIST_ERROR: {
            return {
                ...state,
                message: action.message,
                employeesLoading: false
            };
        }
        case GET_ALL_EMPLOYEE_LIST_SUCCESS: {
            const { companyId, employeeList } = action.payload;
            let newAllCompanyEmployeeList = state.allCompanyEmployeeList || {};
            newAllCompanyEmployeeList[companyId] = employeeList;
            return {
                ...state,
                allCompanyEmployeeList: newAllCompanyEmployeeList,
                message: '',
                employeesLoading: false
            };
        }
        case GET_ALL_EMPLOYEE_LIST_ERROR: {
            return {
                ...state,
                message: action.message,
                employeesLoading: false
            };
        }
        case GET_MNG_DASHBOARD_STATS_SUCCESS: {
            return {
                ...state,
                mngDashboardStats: action.payload,
                message: ''
            };
        }
        case GET_MNG_DASHBOARD_STATS_ERROR: {
            return {
                ...state,
                message: action.message
            };
        }
        case REFRESH_LIVE_DATA_LOADING:
            return {
                ...state,
                liveDataLoading: true
            };
        case REFRESH_LIVE_DATA_LOADED:
            return {
                ...state,
                liveDataLoading: false
            };
        default:
            return state;
    }
};

export default data;
