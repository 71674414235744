export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const GET_PHONE_NUMBER = 'GET_PHONE_NUMBER';
export const VERIFY_PHONE_NUMBER = 'VERIFY_PHONE_NUMBER';
export const COMPLETE_VERIFICATION = 'COMPLETE_VERIFICATION';
export const GET_VERIFICATION_CODE = 'GET_VERIFICATION_CODE';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE';
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE';
export const SHOW_LOADING = 'SHOW_LOADING';
export const AUTH_TOKEN = 'auth_token';
export const UID = 'uid';
