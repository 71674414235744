import axios from 'axios';
import { API_BASE_URL, NEW_BRANCH_FORM_PATH } from 'configs/AppConfig';
import history from '../history';
import { AUTH_TOKEN } from 'redux/constants/Auth';
import { notification } from 'antd';
import Utils from 'utils';

const service = axios.create({
	baseURL: API_BASE_URL,
	timeout: 600000,
});

const notificationOptions = {
	duration: 0,
};

// Config
const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_TOKEN = 'public-auth';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
service.interceptors.request.use(
	async (config) => {
		if (window.location.pathname === NEW_BRANCH_FORM_PATH) {
			//config.headers[PUBLIC_TOKEN] = `salix ${process.env.REACT_APP_UUID}`;
		} else {
			const jwtToken = localStorage.getItem(AUTH_TOKEN);
			if (jwtToken) {
				config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
			}

			if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
				Utils.emptyState();
				history.push(ENTRY_ROUTE);
				//window.location.reload();
			}
		}

		return config;
	},
	(error) => {
		// Do something with request error here
		notification.error(...notificationOptions, {
			message: 'Error',
		});
		Promise.reject(error);
	}
);

// API respone interceptor
service.interceptors.response.use(
	(response) => {
		return response.data;
	},
	(error) => {
		let code = error && error.response ? error.response.status : '000';
		let notificationParam = { ...notificationOptions, message: '', code };

		// Remove token and redirect
		if (code === 401 || code === 403) {
			notificationParam.message = 'Doğrulama Başarısız';
			notificationParam.description = 'Lütfen tekrar giriş yapınız';
			Utils.emptyState();
			history.push(ENTRY_ROUTE);
			//window.location.reload();
		}

		if (error.response.status === 404 || error.response.status === 405) {
			notificationParam.message = Utils.extractErrorMessage(error) || 'Servis bulunamadı';
		} else if (error.response.status === 500) {
			notificationParam.message = Utils.extractErrorMessage(error) || 'Sunucu Hatası';
		} else if (error.response.status === 508) {
			notificationParam.message = Utils.extractErrorMessage(error) || 'Zaman Aşımı!';
		} else if (error.response.status === 400) {
			notificationParam.message = Utils.extractErrorMessage(error) || 'Servis parametrelerinde hata!';
		}

		if (code === '000') {
			notificationParam.message = error && error.response ? error.response : error;
		}

		notification.error(notificationParam);

		return Promise.reject(error);
	}
);

export default service;
