import { Modal } from 'antd';
import ExportJsonExcel from 'js-export-excel';
import _ from 'lodash';
import { sha256 } from 'crypto-hash';

const { confirm } = Modal;

class Utils {
	/**
	 * Get first character from first & last sentences of a username
	 * @param {String} name - Username
	 * @return {String} 2 characters string
	 */
	static getNameInitial(name) {
		let initials = name.match(/\b\w/g) || [];
		return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
	}

	/**
	 * Get current path related object from Navigation Tree
	 * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
	 * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
	 * @return {Object} object that contained the path string
	 */
	static getRouteInfo(navTree, path) {
		if (navTree.path === path) {
			return navTree;
		}
		let route;
		for (let p in navTree) {
			if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
				route = this.getRouteInfo(navTree[p], path);
				if (route) {
					return route;
				}
			}
		}
		return route;
	}

	/**
	 * Get accessible color contrast
	 * @param {String} hex - Hex color code e.g '#3e82f7'
	 * @return {String} 'dark' or 'light'
	 */
	static getColorContrast(hex) {
		if (!hex) {
			return 'dark';
		}
		const threshold = 130;
		const hRed = hexToR(hex);
		const hGreen = hexToG(hex);
		const hBlue = hexToB(hex);
		function hexToR(h) {
			return parseInt(cutHex(h).substring(0, 2), 16);
		}
		function hexToG(h) {
			return parseInt(cutHex(h).substring(2, 4), 16);
		}
		function hexToB(h) {
			return parseInt(cutHex(h).substring(4, 6), 16);
		}
		function cutHex(h) {
			return h.charAt(0) === '#' ? h.substring(1, 7) : h;
		}
		const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
		if (cBrightness > threshold) {
			return 'dark';
		} else {
			return 'light';
		}
	}

	/**
	 * Darken or lighten a hex color
	 * @param {String} color - Hex color code e.g '#3e82f7'
	 * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
	 * @return {String} Darken or lighten color
	 */
	static shadeColor(color, percent) {
		let R = parseInt(color.substring(1, 3), 16);
		let G = parseInt(color.substring(3, 5), 16);
		let B = parseInt(color.substring(5, 7), 16);
		R = parseInt((R * (100 + percent)) / 100);
		G = parseInt((G * (100 + percent)) / 100);
		B = parseInt((B * (100 + percent)) / 100);
		R = R < 255 ? R : 255;
		G = G < 255 ? G : 255;
		B = B < 255 ? B : 255;
		const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
		const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
		const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
		return `#${RR}${GG}${BB}`;
	}

	/**
	 * Convert RGBA to HEX
	 * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
	 * @return {String} HEX color
	 */
	static rgbaToHex(rgba) {
		const trim = (str) => str.replace(/^\s+|\s+$/gm, '');
		const inParts = rgba.substring(rgba.indexOf('(')).split(','),
			r = parseInt(trim(inParts[0].substring(1)), 10),
			g = parseInt(trim(inParts[1]), 10),
			b = parseInt(trim(inParts[2]), 10),
			a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
		const outParts = [
			r.toString(16),
			g.toString(16),
			b.toString(16),
			Math.round(a * 255)
				.toString(16)
				.substring(0, 2),
		];

		outParts.forEach(function (part, i) {
			if (part.length === 1) {
				outParts[i] = '0' + part;
			}
		});
		return `#${outParts.join('')}`;
	}

	/**
	 * Returns either a positive or negative
	 * @param {Number} number - number value
	 * @param {any} positive - value that return when positive
	 * @param {any} negative - value that return when negative
	 * @return {any} positive or negative value based on param
	 */
	static getSignNum(number, positive, negative) {
		if (number > 0) {
			return positive;
		}
		if (number < 0) {
			return negative;
		}
		return null;
	}

	/**
	 * Returns either ascending or descending value
	 * @param {Object} a - antd Table sorter param a
	 * @param {Object} b - antd Table sorter param b
	 * @param {String} key - object key for compare
	 * @return {any} a value minus b value
	 */
	static antdTableSorter(a, b, key) {
		if (typeof a[key] === 'number' && typeof b[key] === 'number') {
			return a[key] - b[key];
		}

		if (typeof a[key] === 'string' && typeof b[key] === 'string') {
			a = a[key].toLowerCase();
			b = b[key].toLowerCase();
			return a > b ? -1 : b > a ? 1 : 0;
		}
		return;
	}

	/**
	 * Filter array of object
	 * @param {Array} list - array of objects that need to filter
	 * @param {String} key - object key target
	 * @param {any} value  - value that excluded from filter
	 * @return {Array} a value minus b value
	 */
	static filterArray(list, key, value) {
		let data = list;
		if (list) {
			data = list.filter((item) => item[key] === value);
		}
		return data;
	}

	/**
	 * Remove object from array by value
	 * @param {Array} list - array of objects
	 * @param {String} key - object key target
	 * @param {any} value  - target value
	 * @return {Array} Array that removed target object
	 */
	static deleteArrayRow(list, key, value) {
		let data = list;
		if (list) {
			data = list.filter((item) => item[key] !== value);
		}
		return data;
	}

	/**
	 * Wild card search on all property of the object
	 * @param {Number | String} input - any value to search
	 * @param {Array} list - array for search
	 * @return {Array} array of object contained keyword
	 */
	static wildCardSearch(list, input) {
		const searchText = (item) => {
			for (let key in item) {
				if (item[key] == null) {
					continue;
				}
				if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
					return true;
				}
			}
		};
		list = list.filter((value) => searchText(value));
		return list;
	}

	/**
	 * Get Breakpoint
	 * @param {Object} screens - Grid.useBreakpoint() from antd
	 * @return {Array} array of breakpoint size
	 */
	static getBreakPoint(screens) {
		let breakpoints = [];
		for (const key in screens) {
			if (screens.hasOwnProperty(key)) {
				const element = screens[key];
				if (element) {
					breakpoints.push(key);
				}
			}
		}
		return breakpoints;
	}

	static loadState = () => {
		try {
			// It's also possible to use other local storage if it doesn't support localStorage
			const serializedState = localStorage.getItem('state');
			//let fifteenMinutesAgo = new Date(new Date() - 15 * 60000);
			if (serializedState === null) {
				this.emptyState();
				return undefined;
			} else {
				const state = JSON.parse(serializedState);
				/*if (fifteenMinutesAgo > new Date(state.lastSaved)) {
					this.emptyState();
					return undefined;
				}*/
				state.auth.loading = false;
				state.auth.pageMode = 'LOGIN';
				return state;
			}
		} catch (err) {
			// ...error handling
			return undefined;
		}
	};

	static saveState = (state) => {
		try {
			state.lastSaved = new Date();
			const serializedState = JSON.stringify(state);
			localStorage.setItem('state', serializedState);
		} catch (err) {
			// ...error handling
		}
	};

	static emptyState = () => {
		try {
			localStorage.removeItem('state');
			localStorage.removeItem('auth_token');
			localStorage.removeItem('uid');
			localStorage.removeItem('_grecaptcha');
		} catch (err) {
			// ...error handling
		}
	};

	static getRegions = () => {
		return [
			{
				key: 'ADANA',
				value: 'ADANA',
			},
			{
				key: 'ANKARA',
				value: 'ANKARA',
			},
			{
				key: 'ANTALYA',
				value: 'ANTALYA',
			},
			{
				key: 'BAKIRKÖY',
				value: 'BAKIRKÖY',
			},
			{
				key: 'BURSA',
				value: 'BURSA',
			},
			{
				key: 'DİYARBAKIR',
				value: 'DİYARBAKIR',
			},
			{
				key: 'ERZURUM',
				value: 'ERZURUM',
			},
			{
				key: 'GAZİANTEP',
				value: 'GAZİANTEP',
			},
			{
				key: 'İSTANBUL',
				value: 'İSTANBUL',
			},
			{
				key: 'İZMİR',
				value: 'İZMİR',
			},
			{
				key: 'KARADENİZ',
				value: 'KARADENİZ',
			},
			{
				key: 'KOCAELİ',
				value: 'KOCAELİ',
			},
			{
				key: 'KONYA',
				value: 'KONYA',
			},
			{
				key: 'MARMARA',
				value: 'MARMARA',
			},
		];
	};

	static exportDataToExcel = (branchName, filename, dataList, headerList, keyList, multiSheet) => {
		var option = { datas: [] };
		if (multiSheet) {
			let dataTable = [];
			_.each(dataList, (branchData) => {
				if (branchData && !_.isEmpty(branchData.data)) {
					branchData.data.forEach((data) => {
						let excelData = {};
						keyList.forEach((key) => {
							excelData[key] = data[key];
						});
						let branchUniqueName = branchData.branchName;
						excelData.branchUniqueName = branchUniqueName;
						if (!_.isEmpty(branchUniqueName) && branchUniqueName.indexOf('-') > -1) {
							let seperatorIndex = branchUniqueName.lastIndexOf('-');
							excelData.branchName = branchUniqueName.substring(0, seperatorIndex);
							excelData.branchTitle = branchUniqueName.substring(seperatorIndex + 1);
						}
						dataTable.push(excelData);
					});
				}
			});
			option.fileName = filename;
			option.datas.push({
				sheetData: dataTable,
				sheetName: 'Acente Çalışan Listesi',
				sheetHeader: headerList,
			});
		} else {
			let dataTable = [];
			if (dataList) {
				dataList.forEach((data) => {
					let excelData = {};
					keyList.forEach((key) => {
						excelData[key] = data[key];
					});
					let branchUniqueName = branchName;
					excelData.branchUniqueName = branchUniqueName;
					if (!_.isEmpty(branchUniqueName) && branchUniqueName.indexOf('-') > -1) {
						let seperatorIndex = branchUniqueName.lastIndexOf('-');
						excelData.branchName = branchUniqueName.substring(0, seperatorIndex);
						excelData.branchTitle = branchUniqueName.substring(seperatorIndex + 1);
					}
					dataTable.push(excelData);
				});
			}
			option.fileName = filename;
			option.datas = [
				{
					sheetData: dataTable,
					sheetName: branchName.length > 31 ? branchName.substring(0, 31) : branchName,
					sheetHeader: headerList,
					columnWidths: [20, 5, 12, 15, 5, 5, 5],
				},
			];
		}

		var toExcel = new ExportJsonExcel(option);
		toExcel.saveExcel();
	};

	static exportDebtListToExcel = (branchName, debtList, headerList, keyList, multiSheet) => {
		var option = { datas: [] };
		if (multiSheet) {
			let sheetNames = [];
			_.each(debtList, (branchDebt) => {
				if (branchDebt && !_.isEmpty(branchDebt.data)) {
					let dataTable = [];
					branchDebt.data.forEach((debt) => {
						let data = {};
						keyList.forEach((key) => {
							let value = debt[key];
							if (value) {
								value = value.replace('.', '');
								value = value.replace(',', '.').trim();
							}
							data[key] = value ? Number(value) : '';
						});
						dataTable.push(data);
					});
					option.fileName = `Borç Listesi`;
					let sheetName = this.validateSheetName(
						sheetNames,
						branchDebt.branchName.length > 31
							? branchDebt.branchName.substring(0, 31)
							: branchDebt.branchName
					);

					option.datas.push({
						sheetData: dataTable,
						sheetName,
						sheetHeader: headerList,
					});
				}
			});
		} else {
			let dataTable = [];
			if (debtList) {
				debtList.forEach((debt) => {
					let data = {};
					keyList.forEach((key) => {
						let value = debt[key];
						if (value) {
							value = value.replaceAll('.', '');
							value = value.replace(',', '.').trim();
						}
						data[key] = value ? Number(value) : '';
					});
					dataTable.push(data);
				});
			}
			option.fileName = `${branchName} Borç Listesi`;
			option.datas = [
				{
					sheetData: dataTable,
					sheetName: branchName.length > 31 ? branchName.substring(0, 31) : branchName,
					sheetHeader: headerList,
				},
			];
		}

		var toExcel = new ExportJsonExcel(option);
		toExcel.saveExcel();
	};

	static validateSheetName = (sheetNames, sheetName) => {
		if (sheetName) sheetName = sheetName.trim();
		sheetName = sheetName.replaceAll('\\', ' ');
		sheetName = sheetName.replaceAll('/', ' ');
		sheetName = sheetName.replaceAll('?', ' ');
		sheetName = sheetName.replaceAll('*', ' ');
		sheetName = sheetName.replaceAll('[', ' ');
		sheetName = sheetName.replaceAll(']', ' ');

		sheetNames.push(sheetName);
		if (sheetNames.includes(sheetName)) {
			let sheetNameCount = _.countBy(sheetNames)[sheetName];
			for (let i = 0; i < sheetNameCount; i++) {
				sheetName += '-';
			}
			if (sheetName.length > 31) {
				sheetName = sheetName.substring(0, 31 - sheetNameCount) + sheetName.substring(31);
			}
		}
		return sheetName;
	};

	static exportDebtSummaryToExcel = (debtSummaryList) => {
		const headerList = [
			'BENZERSİZ KODLAMA',
			'KULLANICI KODU',
			'SGK İŞYERİ SİCİLİ',
			'ACENTE ADI',
			'BÖLGE',
			'BORÇ DURUMU',
			'BORÇ TUTARI',
		];
		var option = { datas: [] };
		let dataTable = [];
		if (debtSummaryList) {
			debtSummaryList.forEach((debt) => {
				dataTable.push(debt);
			});
		}
		option.fileName = 'Borç Özet Liste';
		option.datas = [
			{
				sheetData: dataTable,
				sheetName: 'Borç Özeti',
				sheetHeader: headerList,
				columnWidths: [15, 5, 12, 15, 5, 5, 5],
			},
		];
		var toExcel = new ExportJsonExcel(option);
		toExcel.saveExcel();
	};

	static getMonths = () => {
		return [
			{
				key: '01',
				value: 'Ocak',
			},
			{
				key: '02',
				value: 'Şubat',
			},
			{
				key: '03',
				value: 'Mart',
			},
			{
				key: '04',
				value: 'Nisan',
			},
			{
				key: '05',
				value: 'Mayıs',
			},
			{
				key: '06',
				value: 'Haziran',
			},
			{
				key: '07',
				value: 'Temmuz',
			},
			{
				key: '08',
				value: 'Ağustos',
			},
			{
				key: '09',
				value: 'Eylül',
			},
			{
				key: '10',
				value: 'Ekim',
			},
			{
				key: '11',
				value: 'Kasım',
			},
			{
				key: '12',
				value: 'Aralık',
			},
		];
	};

	static isNumeric = (value) => {
		return !isNaN(parseFloat(value)) && isFinite(value);
	};

	static extractErrorMessage = (error) => {
		let message = '';
		if (!error) {
			return 'Servis çağırım hatası!';
		}
		if (!error.response) {
			return error;
		}
		if (error.response.status === 404 || error.response.status === 405 || error.response.status === 400) {
			if (error && error.response && error.response.data) {
				if (typeof error.response.data === 'string') {
					message = error.response.data;
				} else if (error.response.data && error.response.data.errorDetail) {
					message = error.response.data.errorDetail;
				} else {
					message = 'Servis bulunamadı';
				}
			}
		}

		if (error.response.status === 500) {
			message = 'Sunucu Hatası';
			if (error.response.data) {
				if (typeof error.response.data === 'string') {
					message = error.response.data;
				} else if (error.response.data && error.response.data.errorDetail) {
					message = error.response.data.errorDetail;
				}
			}
		}

		if (error.response.status === 508) {
			if (typeof error.response.data === 'string') {
				message = error.response.data;
			} else if (error.response.data && error.response.data.errorDetail) {
				message = error.response.data.errorDetail;
			} else {
				message = 'Zaman Aşımı!';
			}
		}
		return message;
	};

	static authenticateNewForm = async (value) => {
		let result = await sha256(value);
		return result === process.env.REACT_APP_NEW_FORM_AUTH;
	};
}

export const showConfirm = (title, content, onConfirm, onCancel) => {
	confirm({
		title,
		content,
		onOk: onConfirm,
		onCancel,
	});
};

export default Utils;
