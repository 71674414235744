import { all } from 'redux-saga/effects';
import Auth from './Auth';
import App from './App';
import User from './User';
import Branch from './Branch';
import Data from './Data';
import Sgk from './SGK';

export default function* rootSaga(getState) {
	yield all([Auth(), App(), User(), Branch(), Data(), Sgk()]);
}
