import {
	SIGNIN,
	AUTHENTICATED,
	GET_PHONE_NUMBER,
	VERIFY_PHONE_NUMBER,
	COMPLETE_VERIFICATION,
	GET_VERIFICATION_CODE,
	SIGNOUT,
	SIGNOUT_SUCCESS,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNUP,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
} from '../constants/Auth';

export const signIn = (user, recaptchaVerifier) => {
	return {
		type: SIGNIN,
		payload: { ...user, recaptchaVerifier },
	};
};

export const authenticated = (uid, token, userObject) => {
	return {
		type: AUTHENTICATED,
		payload: { uid, token, userObject },
	};
};

export const getPhoneNumber = (user) => {
	return {
		type: GET_PHONE_NUMBER,
		user,
	};
};

export const verifyPhoneNumber = (user, phoneNumber, recaptchaVerifier) => {
	return {
		type: VERIFY_PHONE_NUMBER,
		payload: { user, phoneNumber, recaptchaVerifier },
	};
};

export const completeVerification = (user, resolver, verificationId, verificationCode) => {
	return {
		type: COMPLETE_VERIFICATION,
		payload: { user, resolver, verificationId, verificationCode },
	};
};

export const getVerificationCode = (verificationId, resolver, user) => {
	return {
		type: GET_VERIFICATION_CODE,
		payload: { user, verificationId, resolver },
	};
};

export const signOut = () => {
	return {
		type: SIGNOUT,
	};
};

export const signOutSuccess = () => {
	return {
		type: SIGNOUT_SUCCESS,
	};
};

export const signUp = (user) => {
	return {
		type: SIGNUP,
		payload: user,
	};
};

export const signUpSuccess = (token) => {
	return {
		type: SIGNUP_SUCCESS,
		token,
	};
};

export const showAuthMessage = (message) => {
	return {
		type: SHOW_AUTH_MESSAGE,
		message,
	};
};

export const hideAuthMessage = () => {
	return {
		type: HIDE_AUTH_MESSAGE,
	};
};

export const showLoading = () => {
	return {
		type: SHOW_LOADING,
	};
};
