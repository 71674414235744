import {
	CREATE_NEW_USER,
	USER_CREATED,
	NEW_USER_ERROR,
	HIDE_NEW_USER_ERROR,
	GET_USER,
	USER_UPDATED,
} from '../constants/User';

export const createUser = (user) => {
	return {
		type: CREATE_NEW_USER,
		payload: user,
	};
};

export const newUserError = (message) => {
	return {
		type: NEW_USER_ERROR,
		message,
	};
};

export const hideNewUserError = () => {
	return {
		type: HIDE_NEW_USER_ERROR,
	};
};

export const userCreated = () => {
	return {
		type: USER_CREATED,
	};
};

export const getUser = (uid) => {
	return {
		type: GET_USER,
		payload: { uid },
	};
};

export const userUpdated = (userObject) => {
	return {
		type: USER_UPDATED,
		payload: { userObject },
	};
};
