import { AUTHENTICATED } from 'redux/constants/Auth';
import { USER_CREATED, NEW_USER_ERROR, HIDE_NEW_USER_ERROR } from '../constants/User';

const initState = {
	message: '',
	showMessage: false,
	createUserSuccess: false,
	displayName: '',
};

const user = (state = initState, action) => {
	switch (action.type) {
		case USER_CREATED:
			return {
				...state,
				loading: false,
				createUserSuccess: true,
			};
		case NEW_USER_ERROR:
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false,
				createUserSuccess: false,
			};
		case HIDE_NEW_USER_ERROR:
			return {
				...state,
				message: '',
				showMessage: false,
			};
		case AUTHENTICATED:
			return {
				...state,
				displayName: action.payload.userObject ? action.payload.userObject.name : '',
				group: action.payload.userObject ? action.payload.userObject.group : '',
			};
		default:
			return state;
	}
};

export default user;
