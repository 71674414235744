import fetch from 'auth/FetchInterceptor';
import { PARSER_SERVICE_URL } from 'constants/ApiConstant';

const parserService = {};

parserService.refreshDebtListBySgkId = async (companyId, sgkId) => {
	return await fetch({
		url: `${PARSER_SERVICE_URL}/periodic-debt-inquiry?pageType=periodic&companyId=${companyId}&sgkId=${sgkId}`,
		method: 'get',
	});
};

parserService.refreshLiveData = async (companyId) => {
	let today = new Date();
	let currentYear = today.getFullYear();
	let currentMonth = today.getMonth() + 1;
	let currentDay = today.getDate();
	let periodIndex = '1';
	let period = currentYear + '-' + getTwoDigitMonth(currentMonth);
	if (currentDay >= 1 && currentDay <= 26) {
		periodIndex = '2';
		period = currentYear + '-' + getTwoDigitMonth(currentMonth - 1);
	}
	return await fetch({
		url: `${PARSER_SERVICE_URL}/run-whole-system`,
		method: 'post',
		data: { companyId, period, periodIndex },
	});
};

const getTwoDigitMonth = (month) => {
	if (month < 10) return '0' + month;
	return month;
};

parserService.branchFormControl = async (branch) => {
	return await fetch({
		url: `${PARSER_SERVICE_URL}/branch-form-control`,
		method: 'post',
		data: branch,
	});
};

export default parserService;
