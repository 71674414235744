import _ from 'lodash';
import { auth, firebase, db } from 'auth/FirebaseAuth';

const FirebaseService = {};

FirebaseService.signInEmailRequest = async (email, password) =>
    await auth
        .signInWithEmailAndPassword(email, password)
        .then((user) => user)
        .catch((err) => err);

FirebaseService.verifyPhoneNumber = async (user, phoneNumber, recaptchaVerifier) => {
    return new Promise((resolve, reject) => {
        user.multiFactor
            .getSession()
            .then(function (multiFactorSession) {
                // Specify the phone number and pass the MFA session.
                var phoneInfoOptions = {
                    phoneNumber,
                    session: multiFactorSession
                };
                var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
                // Send SMS verification code.
                return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
            })
            .then((verificationId) => {
                resolve(verificationId);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

FirebaseService.sendVerificationCodeForExistingPhone = async (multiFactorHint, multiFactorSession, recaptchaVerifier) => {
    return new Promise((resolve, reject) => {
        let phoneInfoOptions = {
            multiFactorHint,
            session: multiFactorSession
        };

        let phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then((verificationId) => {
                resolve(verificationId);
            })
            .catch((err) => reject(err));
    });
};

FirebaseService.sendVerificationEmail = async (user) =>
    await user
        .sendEmailVerification()
        .then((user) => user)
        .catch((err) => err);

FirebaseService.completePhoneVerification = async (user, resolver, verificationId, verificationCode) => {
    var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    if (user) {
        // complete enrollment
        return user.multiFactor.enroll(multiFactorAssertion, 'First auth verification!');
    } else {
        // Complete sign-in.
        return resolver.resolveSignIn(multiFactorAssertion);
    }
};

FirebaseService.signInEmailRequest = async (email, password) =>
    await auth
        .signInWithEmailAndPassword(email, password)
        .then((user) => user)
        .catch((err) => err);

FirebaseService.signOutRequest = async () =>
    await auth
        .signOut()
        .then((user) => user)
        .catch((err) => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
    await auth
        .createUserWithEmailAndPassword(email, password)
        .then((user) => user)
        .catch((err) => err);

FirebaseService.getAuthToken = async (user) => {
    return new Promise((resolve, reject) => {
        firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                resolve(idToken);
            })
            .catch(function (error) {
                reject(error);
            });
    });
};

FirebaseService.getUserObject = async (uid) => {
    return new Promise((resolve, reject) => {
        db.collection('users')
            .doc(uid)
            .get()
            .then((user) => resolve(user.data()))
            .catch((err) => reject(err));
    });
};

FirebaseService.getMenu = async (group) => {
    return new Promise((resolve, reject) => {
        db.collection('menu')
            .doc(group)
            .collection('menu')
            .get()
            .then(async (groupMenu) => {
                let documents = groupMenu.docs;
                let menuTree = [];
                let promises = [];

                documents.forEach(async (doc) => {
                    let data = doc.data();
                    menuTree.splice(data.order, 0, {
                        key: doc.id,
                        adminOnly: data.admin,
                        path: data.path,
                        title: data.label,
                        icon: data.icon,
                        breadcrumb: false,
                        submenu: []
                    });
                    promises.push(
                        new Promise(async (resolveSubMenu) => {
                            let subMenu = await getSubMenuList(`menu/${group}/menu/${doc.id}/subMenu`);
                            menuTree[data.order].submenu = subMenu;
                            resolveSubMenu();
                        })
                    );
                });
                Promise.all(promises).then(() => {
                    resolve(menuTree);
                });
            })
            .catch((err) => reject(err));
    });
};

const getSubMenuList = async (collectionRefStr) => {
    let subMenuList = await db.collection(collectionRefStr).get();
    let subMenuTree = [];
    let documents = subMenuList.docs;
    if (!_.isEmpty(subMenuList.docs) && _.isArray(documents)) {
        documents.forEach((doc) => {
            let data = doc.data();
            subMenuTree.splice(data.order, 0, {
                key: doc.id,
                adminOnly: data.admin,
                path: data.path,
                title: data.label,
                icon: data.icon,
                breadcrumb: false,
                submenu: [] //await getSubMenuList(`collectionRefStr/${doc.id}/subMenu`),
            });
        });
    }

    return subMenuTree;
};

export default FirebaseService;
