import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';

export const AppViews = ({ userGroup }) => {
    return (
        <Suspense fallback={<Loading cover='content' />}>
            <Switch>
                <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => (userGroup === 'mng' ? import(`./mng/mng-dashboard`) : import(`./home`)))} />
                <Route path={`${APP_PREFIX_PATH}/new-user`} component={lazy(() => import(`./new-user`))} />
                <Route path={`${APP_PREFIX_PATH}/regions`} component={lazy(() => import(`./mng/gmap`))} />
                <Route path={`${APP_PREFIX_PATH}/branch-list/:region`} component={lazy(() => import(`./mng/branch-list`))} />
                <Route exact path={`${APP_PREFIX_PATH}/branch-list`} component={lazy(() => import(`./mng/branch-list`))} />
                <Route exact path={`${APP_PREFIX_PATH}/branch-employees`} component={lazy(() => import(`./mng/branch-employees`))} />
                <Route exact path={`${APP_PREFIX_PATH}/debt`} component={lazy(() => import(`./mng/debt-list`))} />
                <Route exact path={`${APP_PREFIX_PATH}/sgk-job-start`} component={lazy(() => import(`./sgk/job-start`))} />
                <Route exact path={`${APP_PREFIX_PATH}/sgk-job-quit`} component={lazy(() => import(`./sgk/job-quit`))} />
                <Route exact path={`${APP_PREFIX_PATH}/sgk-job-start-quit-bulk`} component={lazy(() => import(`./sgk/job-start-quit-bulk`))} />
                <Route
                    exact
                    path={`${APP_PREFIX_PATH}/encouragement-potential-inquiry`}
                    component={lazy(() => import(`./sgk/encouragement-potential-inquiry`))}
                />
                <Route exact path={`${APP_PREFIX_PATH}/encouragement-unjust-control`} component={lazy(() => import(`./sgk/encouragement-unjust-control`))} />
                <Route exact path={`${APP_PREFIX_PATH}/firm-add`} component={lazy(() => import(`./sgk/firm-add`))} />
                <Route exact path={`${APP_PREFIX_PATH}/firm-add-bulk`} component={lazy(() => import(`./sgk/firm-add-bulk`))} />
                <Route exact path={`${APP_PREFIX_PATH}/firm-list`} component={lazy(() => import(`./sgk/firm-list`))} />
                <Route exact path={`${APP_PREFIX_PATH}/new-branch-approval-list`} component={lazy(() => import('./mng/new-branch-approval-list'))} />

                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
            </Switch>
        </Suspense>
    );
};

export default React.memo(AppViews);
