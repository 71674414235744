import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { GET_ALL_EMPLOYEE_LIST, GET_DEBT_LIST, GET_EMPLOYEE_LIST, GET_MNG_DASHBOARD_STATS, REFRESH_DEBT_DATA, REFRESH_LIVE_DATA } from 'redux/constants/Data';
import {
    getDebtListError,
    getDebtListSuccess,
    debtsLoading,
    getMngDashboardStatsSuccess,
    getMngDashboardStatsError,
    getEmployeeListSuccess,
    getEmployeeListError,
    getAllEmployeeListSuccess,
    getAllEmployeeListError,
    employeeListLoading,
    refreshLiveDataLoading,
    refreshLiveDataLoaded
} from '../actions/Data';
import { signOut } from '../actions/Auth';
import DataService from 'services/DataService';
import ParserService from 'services/ParserService';
import { notification } from 'antd';

export function* getDebtList() {
    yield takeEvery(GET_DEBT_LIST, function* ({ payload }) {
        try {
            yield put(debtsLoading());
            const { companyId } = payload;
            const debtList = yield call(DataService.getDebtListByCompanyId, companyId);
            yield put(getDebtListSuccess(companyId, debtList));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                yield put(signOut());
            } else {
                yield put(getDebtListError(''));
            }
        }
    });
}

export function* refreshDebtData() {
    yield takeEvery(REFRESH_DEBT_DATA, function* ({ payload }) {
        try {
            yield put(debtsLoading());
            const { companyId, sgkId } = payload;
            const refreshResult = yield call(ParserService.refreshDebtListBySgkId, companyId, sgkId);
            if ('OK' === refreshResult) {
                const debtList = yield call(DataService.getDebtListByCompanyId, companyId);
                yield put(getDebtListSuccess(companyId, debtList));
            } else {
                yield put(getDebtListError('Güncel veri alınırken bir hata oluştu'));
            }
        } catch (error) {
            yield put(getDebtListError('Güncel veri alınırken bir hata oluştu'));
        }
    });
}

export function* getEmployeeList() {
    yield takeEvery(GET_EMPLOYEE_LIST, function* ({ payload }) {
        try {
            yield put(employeeListLoading());
            const { companyId, sgkId, period } = payload;
            const employeeList = yield call(DataService.getEmployeeList, companyId, sgkId, period);
            yield put(getEmployeeListSuccess(companyId, employeeList));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                yield put(signOut());
            } else {
                yield put(getEmployeeListError(''));
            }
        }
    });
}

export function* getAllEmployeeList() {
    yield takeEvery(GET_ALL_EMPLOYEE_LIST, function* ({ payload }) {
        try {
            const { companyId } = payload;
            const employeeList = yield call(DataService.getAllEmployeeListLatestPeriod, companyId);
            yield put(getAllEmployeeListSuccess(companyId, employeeList));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                yield put(signOut());
            } else {
                yield put(getAllEmployeeListError(''));
            }
        }
    });
}

export function* getMngDashboardStats() {
    yield takeEvery(GET_MNG_DASHBOARD_STATS, function* ({ payload }) {
        try {
            const { uid } = payload;
            const mngDashboardStats = yield call(DataService.getDashboardStats, uid);
            yield put(getMngDashboardStatsSuccess(mngDashboardStats));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                yield put(signOut());
            } else {
                yield put(getMngDashboardStatsError(''));
            }
        }
    });
}

export function* refreshLiveData() {
    yield takeEvery(REFRESH_LIVE_DATA, function* ({ payload }) {
        try {
            const { companyId } = payload;
            yield put(refreshLiveDataLoading());
            const response = yield call(ParserService.refreshLiveData, companyId);
            let message = 'Servis hatası!';
            if (response && response.status) {
                if (response.status === 'Fail') {
                    notification.error({ duration: 0, message: response.message });
                } else {
                    notification.success({ duration: 0, message: response.message });
                }
            } else {
                notification.info({ duration: 0, message });
            }
            yield put(refreshLiveDataLoaded());
        } catch (error) {
            if (error.response && error.response.status === 401) {
                yield put(signOut());
            } else {
                yield put(getMngDashboardStatsError(''));
            }
            yield put(refreshLiveDataLoaded());
        }
    });
}

export default function* rootSaga() {
    yield all([fork(getDebtList), fork(getEmployeeList), fork(getAllEmployeeList), fork(getMngDashboardStats), fork(refreshDebtData), fork(refreshLiveData)]);
}
