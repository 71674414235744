import { combineReducers } from 'redux';
import Auth from './Auth';
import App from './App';
import Theme from './Theme';
import User from './User';
import Branch from './Branch';
import Data from './Data';
import Sgk from './SGK';
import Utils from 'utils';

const reducers = combineReducers({
	theme: Theme,
	auth: Auth,
	app: App,
	user: User,
	branch: Branch,
	data: Data,
	sgk: Sgk,
});

const rootReducer = (state, action) => {
	if (action.type === 'SIGNOUT_SUCCESS') {
		Utils.emptyState();
		return reducers(undefined, action);
	}

	return reducers(state, action);
};

export default rootReducer;
