import _ from 'lodash';
import {
	CLEAR_MESSAGE,
	JOB_QUIT_ERROR,
	JOB_QUIT_SUCCESS,
	JOB_START_ERROR,
	JOB_START_SUCCESS,
	WAIT_JOB_QUIT,
	WAIT_JOB_START,
} from 'redux/constants/SGK';

const initState = {
	employeeList: [],
	referenceCodeList: [],
	jobStartMessage: '',
	jobQuitMessage: '',
	showLoading: false,
	success: false,
};

const sgk = (state = initState, action) => {
	switch (action.type) {
		case CLEAR_MESSAGE:
			return {
				...state,
				jobStartMessage: '',
				jobQuitMessage: '',
				showLoading: false,
				success: false,
				employeeList: [],
				referenceCodeList: [],
			};
		case JOB_START_SUCCESS:
			return {
				...state,
				referenceCodeList: action.payload.referenceCodeList,
				jobStartMessage: '',
				showLoading: false,
				success: false,
			};
		case WAIT_JOB_START:
			return {
				...state,
				jobStartMessage: '',
				showLoading: true,
				success: false,
				referenceCodeList: [],
			};
		case JOB_START_ERROR:
			return {
				...state,
				jobStartMessage: action.message,
				showLoading: false,
				success: false,
				referenceCodeList: [],
			};
		case JOB_QUIT_SUCCESS:
			return {
				...state,
				referenceCodeList: action.payload.referenceCodeList,
				jobQuitMessage: '',
				showLoading: false,
				success: false,
			};
		case WAIT_JOB_QUIT:
			return {
				...state,
				jobQuitMessage: '',
				showLoading: true,
				success: false,
				referenceCodeList: [],
			};
		case JOB_QUIT_ERROR:
			return {
				...state,
				jobQuitMessage: action.message,
				showLoading: false,
				success: false,
				referenceCodeList: [],
			};
		default:
			return state;
	}
};

export default sgk;
