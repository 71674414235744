import fetch from 'auth/FetchInterceptor';
import { QUERY_SERVICE_URL, STATISTICS_SERVICE_URL } from 'constants/ApiConstant';

const dataService = {};

dataService.getDebtListByCompanyId = async (companyId) => {
	return await fetch({
		url: `${QUERY_SERVICE_URL}/periodic-debt/${companyId}`,
		method: 'get',
	});
};

dataService.getDashboardStats = async () => {
	return await fetch({
		url: `${STATISTICS_SERVICE_URL}/mng-dashboard`,
		method: 'get',
	});
};

dataService.getAllEmployeeListLatestPeriod = async (companyId) => {
	return await fetch({
		url: `${QUERY_SERVICE_URL}/approved/${companyId}`,
		method: 'get',
	});
};

dataService.getEmployeeList = async (companyId, sgkId, period) => {
	return await fetch({
		url: `${QUERY_SERVICE_URL}/approved-with-period/${companyId}?sgkId=${sgkId}&period=${period}`,
		method: 'get',
	});
};

dataService.getMenuByGroup = async (group) => {
	return await fetch({
		url: `${QUERY_SERVICE_URL}/menu?documentId=${group}`,
		method: 'get',
	});
};

export default dataService;
