import {
	JOB_QUIT_BULK,
	JOB_QUIT_ERROR,
	JOB_QUIT_SUCCESS,
	JOB_START_MULTI,
	JOB_START_ERROR,
	JOB_START_SUCCESS,
	WAIT_JOB_QUIT,
	WAIT_JOB_START,
	JOB_START_BULK,
	JOB_QUIT_MULTI,
	CLEAR_MESSAGE,
} from '../constants/SGK';

export const clearMessage = () => {
	return {
		type: CLEAR_MESSAGE,
	};
};

export const jobStartMulti = (uid, branchId, employeeList) => {
	return {
		type: JOB_START_MULTI,
		payload: {
			uid,
			branchId,
			employeeList,
		},
	};
};

export const jobStartError = (message) => {
	return {
		type: JOB_START_ERROR,
		message,
	};
};

export const jobStartSuccess = (referenceCodeList) => {
	return {
		type: JOB_START_SUCCESS,
		payload: { referenceCodeList },
	};
};

export const waitJobStart = () => {
	return {
		type: WAIT_JOB_START,
	};
};

export const jobStartBulk = (file) => {
	return {
		type: JOB_START_BULK,
		payload: {
			file,
		},
	};
};

export const jobQuitMulti = (uid, branchId, employeeList) => {
	return {
		type: JOB_QUIT_MULTI,
		payload: {
			uid,
			branchId,
			employeeList,
		},
	};
};

export const jobQuitBulk = (file) => {
	return {
		type: JOB_QUIT_BULK,
		payload: {
			file,
		},
	};
};

export const jobQuitError = (message) => {
	return {
		type: JOB_QUIT_ERROR,
		message,
	};
};

export const jobQuitSuccess = (employeeList) => {
	return {
		type: JOB_QUIT_SUCCESS,
		payload: { employeeList },
	};
};

export const waitJobQuit = () => {
	return {
		type: WAIT_JOB_QUIT,
	};
};
