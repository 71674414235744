import fetch from 'auth/FetchInterceptor';
import { USER_SERVICE_URL } from 'constants/ApiConstant';

const userService = {};

userService.getUser = async (uid) => {
	return await fetch({
		url: `${USER_SERVICE_URL}/detail/${uid}`,
		method: 'get',
	});
};

userService.createUser = async (user) => {
	return await fetch({
		url: `${USER_SERVICE_URL}/create`,
		method: 'post',
		data: user,
	});
};

export default userService;
