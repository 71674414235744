import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Grid, Spin, Button } from 'antd';
import * as Icons from '@ant-design/icons';
import Icon from '../util-components/Icon';
import _ from 'lodash';
import navigationConfig from 'configs/NavigationConfig';
import { connect } from 'react-redux';
import { SIDE_NAV_LIGHT } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';
import { APP_PREFIX_PATH } from './../../configs/AppConfig';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

//const setLocale = (isLocaleOn, localeKey) => (isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString());

const setDefaultOpen = (key) => {
	let keyList = [];
	let keyString = '';
	if (key) {
		const arr = key.split('-');
		for (let index = 0; index < arr.length; index++) {
			const elm = arr[index];
			index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
			keyList.push(keyString);
		}
	}
	return keyList;
};

const SideNavContent = (props) => {
	const {
		sideNavTheme,
		routeInfo,
		hideGroupTitle,
		onMobileNavToggle,
		isAdmin,
		menuTree,
		loading,
		approvalBranchList,
	} = props;

	const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false);
		}
	};
	let menuList = !_.isEmpty(menuTree) ? menuTree : navigationConfig;
	let pathname = props.location.pathname.substring(APP_PREFIX_PATH.length + 1);
	pathname = pathname.substring(0, pathname.indexOf('/') > -1 ? pathname.indexOf('/') : pathname.length);
	if (loading) {
		return (
			<div align='center' style={{ marginTop: '100px' }}>
				<Spin />
			</div>
		);
	}
	return (
		<Menu
			theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
			mode='inline'
			style={{ height: '100%', borderRight: 0 }}
			defaultSelectedKeys={[routeInfo?.key]}
			selectedKeys={[pathname]}
			defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
			className={hideGroupTitle ? 'hide-group-title' : ''}
		>
			{menuList.map((menu) => {
				if (!menu.adminOnly || isAdmin) {
					return menu.submenu && menu.submenu.length > 0 ? (
						<Menu.ItemGroup key={menu.key} title={menu.title}>
							{menu.submenu.map((subMenuFirst) =>
								subMenuFirst.submenu && subMenuFirst.submenu.length > 0 ? (
									<SubMenu
										icon={
											subMenuFirst.icon ? (
												<Icon type={Icons[subMenuFirst?.icon] || subMenuFirst?.icon} />
											) : null
										}
										key={subMenuFirst.key}
										title={subMenuFirst.title}
									>
										{subMenuFirst.submenu.map((subMenuSecond) => (
											<Menu.Item key={subMenuSecond.key}>
												{subMenuSecond.icon ? (
													<Icon type={Icons[subMenuFirst?.icon] || subMenuSecond?.icon} />
												) : null}
												<span>{subMenuSecond.title}</span>
												<Link
													onClick={() => closeMobileNav()}
													to={`${APP_PREFIX_PATH}${subMenuSecond.path}`}
												/>
											</Menu.Item>
										))}
									</SubMenu>
								) : (
									<Menu.Item key={subMenuFirst.key}>
										{subMenuFirst.icon ? (
											<Icon type={Icons[subMenuFirst?.icon] || subMenuFirst.icon} />
										) : null}
										<span>{subMenuFirst.title}</span>
										<Link
											onClick={() => closeMobileNav()}
											to={`${APP_PREFIX_PATH}${subMenuFirst.path}`}
										/>
										{subMenuFirst.key === 'new-branch-approval-list' &&
											approvalBranchList &&
											approvalBranchList.length > 0 && (
												<Button
													size='small'
													shape='circle'
													type='primary'
													danger
													style={{
														minWidth: '25px',
														height: '25px',
														float: 'right',
														marginTop: '7px',
														paddingTop: '2px',
														fontWeight: 'bold',
													}}
												>
													{approvalBranchList.length}
												</Button>
											)}
									</Menu.Item>
								)
							)}
						</Menu.ItemGroup>
					) : (
						<Menu.Item key={menu.key}>
							{menu.icon ? <Icon type={Icons[menu?.icon] || menu?.icon} /> : null}
							<span>{menu?.title}</span>
							{menu.path ? (
								<Link onClick={() => closeMobileNav()} to={`${APP_PREFIX_PATH}${menu.path}`} />
							) : null}
						</Menu.Item>
					);
				}
			})}
		</Menu>
	);
};
/*
const TopNavContent = (props) => {
	const { topNavColor, localization, menuTree } = props;
	let menuList = menuTree || navigationConfig;
	return (
		<Menu mode='horizontal' style={{ backgroundColor: topNavColor }}>
			{menuList.map((menu) =>
				menu.submenu.length > 0 ? (
					<SubMenu
						key={menu.key}
						popupClassName='top-nav-menu'
						title={
							<span>
								{menu.icon ? <Icon type={menu?.icon} /> : null}
								<span>{setLocale(localization, menu.title)}</span>
							</span>
						}
					>
						{menu.submenu.map((subMenuFirst) =>
							subMenuFirst.submenu.length > 0 ? (
								<SubMenu
									key={subMenuFirst.key}
									icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
									title={setLocale(localization, subMenuFirst.title)}
								>
									{subMenuFirst.submenu.map((subMenuSecond) => (
										<Menu.Item key={subMenuSecond.key}>
											<span>{setLocale(localization, subMenuSecond.title)}</span>
											<Link to={subMenuSecond.path} />
										</Menu.Item>
									))}
								</SubMenu>
							) : (
								<Menu.Item key={subMenuFirst.key}>
									{subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
									<span>{setLocale(localization, subMenuFirst.title)}</span>
									<Link to={subMenuFirst.path} />
								</Menu.Item>
							)
						)}
					</SubMenu>
				) : (
					<Menu.Item key={menu.key}>
						{menu.icon ? <Icon type={menu?.icon} /> : null}
						<span>{setLocale(localization, menu?.title)}</span>
						{menu.path ? <Link to={menu.path} /> : null}
					</Menu.Item>
				)
			)}
		</Menu>
	);
};
*/
const MenuContent = (props) => {
	return <SideNavContent {...props} />; //props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />;
};

const mapStateToProps = ({ theme, auth, app, branch }) => {
	const { sideNavTheme, topNavColor } = theme;
	const { userType } = auth;
	const { menuTree, loading } = app;
	const { approvalBranchList } = branch;
	return { sideNavTheme, topNavColor, isAdmin: userType === 'admin', menuTree, loading, approvalBranchList };
};

export default connect(mapStateToProps, { onMobileNavToggle })(withRouter(MenuContent));
