import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import { CREATE_NEW_USER, GET_USER } from 'redux/constants/User';
import { userCreated, newUserError, userUpdated } from 'redux/actions/User';
import { signOut } from 'redux/actions/Auth';
import UserService from 'services/UserService';

export function* createNewUser() {
	yield takeEvery(CREATE_NEW_USER, function* ({ payload }) {
		try {
			yield call(UserService.createUser, handleRequestFormatting(payload));
			yield put(userCreated());
		} catch (error) {
			if (error.response && error.response.status === 401) {
				yield put(signOut());
			} else {
				yield put(newUserError(error.message ? error.message : error));
			}
		}
	});
}

export function* getUser() {
	yield takeEvery(GET_USER, function* ({ payload }) {
		try {
			const userData = yield call(UserService.getUser, payload.uid);
			yield put(userUpdated(userData));
		} catch (error) {}
	});
}

const handleRequestFormatting = (user) => {
	let formattedUser = {
		name: user.name,
		password: user.appPassword,
		email: user.email,
		userType: user.userType,
		phoneNumber: '+90' + user.phoneNumber.split(' ').join(''),
		group: user.group ? user.group : 'default',
		following: user.following,
		calculationDay: user.calculationDay,
		region: user.region,
	};

	if ('admin' !== user.userType) {
		formattedUser.sgkUser = [
			{
				sgkBusinessCode: user.businessCode,
				sgkCorporatePassword: user.corporatePassword,
				sgkPassword: user.password,
				sgkUsername: user.sgkUsername,
				region: user.region,
				name: user.name,
				sgkId: user.sgkId,
			},
		];
	}

	return formattedUser;
};

export default function* rootSaga() {
	yield all([fork(createNewUser), fork(getUser)]);
}
